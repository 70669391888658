import { DivIcon, LatLngExpression } from "leaflet";
import { useContext, useEffect } from 'react';
import ReactDOMServer from "react-dom/server";
import { Marker, useMapEvents } from "react-leaflet";
import styles from './Map.module.scss';
import { Icon, IconProps } from "@mui/material";
import { HomeContext } from "../../../context/home.context";
import { useNavigate } from "react-router-dom";
import MapTileLayer from "./MapTileLayer";

export interface IMapLayerProps {

}

function MapLayer(props: IMapLayerProps) {
	const context = useContext(HomeContext);
	const navigate = useNavigate();
	const map = useMapEvents({
		locationfound: (e) => {
			map.flyTo(e.latlng.wrap(), map.getZoom());
			onBoundsChanged();
		},
		dragend: (e) => {
			onBoundsChanged();
			context.setLatLng(e.target.getCenter());
		},
		zoomend: (e) => {
			onBoundsChanged();
			context.setZoom(e.target.getZoom());
		}
	});

	useEffect(() => {
		if (context.latLng) {
			map.flyTo(context.latLng, map.getZoom());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.latLng]);

	function onBoundsChanged() {
		let bounds = map.getBounds();
		if (bounds) {
			context.onBoundsChanged(bounds);
		}
	}

	function getIcon(sportId?: string) {
		let iconOption: IconProps = {
			className: styles.markerIcon,
			color: "primary"
		};
		const sport = context.sports.find(i => i.id === sportId);
		if (sport && sport.icon) {
			return <Icon {...iconOption} >{sport.icon}</Icon>;
		} else {
			return <Icon {...iconOption} >{"sports"}</Icon>;
		}
	}

	return (
		<MapTileLayer>
			{
				context.kicks.map((item, index) =>
					<Marker
						key={index}
						position={{
							lat: item.location.coordinates[1],
							lng: item.location.coordinates[0]
						} as LatLngExpression}
						icon={new DivIcon({
							html: ReactDOMServer.renderToString(getIcon(item.sport)),
							className: styles.marker
						})}
						eventHandlers={{
							click: (e) => {
								navigate(`kick/${item.id}`);
								context.setLatLng(e.latlng);
							}
						}}
					/>
				)
			}
		</MapTileLayer>
	);
}

export default MapLayer;