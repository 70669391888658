import { format, Locale } from "date-fns";
import { enAU } from "date-fns/locale";

export default class Helpers {
	/**
	 * Sort an array of items of type `T` by a property `K`
	 * Defaults to ascending
	 * @param items items to sort
	 * @param prop property to sort by
	 * @param descending optional descending flag, sorts ascending if omitted
	 **/
	public static SortItems<T, K extends keyof T>(
		items: T[],
		prop: K,
		descending?: boolean
	) {
		const sorted = items.sort((a, b) => {
			const aValue = Helpers.GetTypeValue(a[prop]);
			const bValue = Helpers.GetTypeValue(b[prop]);
			if (aValue === null || aValue === undefined) {
				return 1;
			} else if (bValue === null || bValue === undefined) {
				return -1;
			} else if (aValue > bValue) {
				return 1;
			} else if (aValue < bValue) {
				return -1;
			}
			return 0;
		});
		if (descending) return sorted.reverse();
		else return sorted;
	}

	/**
	 * Get current windows dimensions
	 * @returns Windows Dimensions
	 */
	public static getWindowDimensions() {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height,
		};
	}

	/**
	 *  Return a strongly typed value for the specified generic value
	 *  This enables the ability to perform typed methods on the value to help with sorting behaviour
	 *  @param value value to return strong type */
	private static GetTypeValue<T, K extends keyof T>(value: T[K]) {
		switch (typeof value) {
			case "string":
				return String(value).trim().toLocaleUpperCase();
			default:
				return value;
		}
	}

	/**
	*
	* Get a formatted date string from a Date
	*
	* Defaults to dd/MM/YYYY HH:mma
	*
	* Returns empty string if date null or undefined
	*
	* Dependency on date-fns
	* @param date Date/number to format
	* @param formatString date-fns format string to use, default `P p`
	* @param locale Locale to use, default `en-au`
	*/
	public static GetFormattedDate(date: Date | number, formatString: string = "P p", locale: Locale = enAU) {
		return date ? format(date, formatString, { locale }) : "";
	}

	/**
	 * Return a correctly formatted class string for use with className prop
	 * 
	 * 
	 * ```tsx
	 * <div className={classes("class1", "class2", styles.class3)}>
	 *     Test
	 * </div>
	 * ```
	 * 
	 * 
	 * @param strings all class name strings to be applied
	 */
	public static Classes(...strings: string[]) {
		return strings.join(" ");
	}

	/**
	 * Switch item in the `currentIndex` to the `newIndex` in the supplied `array`
	 * @param array 
	 * @param currentIndex 
	 * @param newIndex 
	 */
	public static MoveItemInArray<T>(array: T[], currentIndex: number, newIndex: number) {
		// get the required item
		const item = array[currentIndex];
		// remove it from the array
		array.splice(currentIndex, 1);
		// insert it at the new index
		array.splice(newIndex, 0, item);
	}
}

/**
 * Allows functional components to accept a generic type to pass to component props
 * 
 * ```typescript
 * export function Test<T>(props: GenericFC<IProps<T>>) {}
 * ```
 */
export type GenericFC<T> = T & { children?: React.ReactNode };

export const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);