import { TileLayerProps } from "react-leaflet";
import { Kick } from "../models/Kick.model";
import { OSMPlace } from "../models/Place.model";
import Helpers from "./utilities";

export function GetPlaceName(item?: OSMPlace) {
	let place = [];
	if (item) {
		if (item.error) return "";
		const name = (
			item.namedetails.name ||
			item.address.house_number ||
			item.address.residential
		);
		if (name) place.push(name);
		const street = (
			item.address.road
		)
		if (street && street !== name) place.push(street);
		const suburb = (
			item.address.suburb ||
			item.address.city ||
			item.address.town ||
			item.address.county ||
			item.address.municipality ||
			item.address.village
		)
		if (suburb && suburb !== name) place.push(suburb);
		const state = (
			item.address.state
		)
		if (state) place.push(state);
	}
	return place.join(", ");
}

export function getEventDateString(kick?: Kick) {
	let dateString = "";
	if (kick && kick.start && kick.end) {
		const startDate = Helpers.GetFormattedDate(kick.start, "PPPP");
		const endDate = Helpers.GetFormattedDate(kick.end, "PPPP");
		const startDateTime = Helpers.GetFormattedDate(kick.start, "PPPP p");
		let endDateTime = Helpers.GetFormattedDate(kick.end, "PPPP p");
		if (startDate === endDate) {
			endDateTime = Helpers.GetFormattedDate(kick.end, "p")
		}
		dateString = `${startDateTime} - ${endDateTime}`;
	}
	return dateString.toUpperCase();
}

export function GetMapTheme(): TileLayerProps {
	const MAP_LIGHT = {
		url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
		attribution: "&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
	};
	return MAP_LIGHT;
}