
import { useContext, useState } from 'react';
import { AppContext } from "../../../context/app.context";
import styles from './Login.module.scss';
import { Button, Checkbox, CircularProgress, FormControlLabel, Icon, Link, TextField, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
export default function Login() {
	const context = useContext(AppContext);
	const [email, setEmail] = useState("");
	const [checked, setChecked] = useState(false);

	function clearEmailSent() {
		setEmail("");
		setChecked(false);
		context.setLoginEmailSent(false);
	}

	return (
		<div className={styles.login}>
			<div>
				<Typography variant={"h2"}>{"Let's go for a Kick!"}</Typography>
			</div>
			{
				context.loginEmailSent
					? <div className={styles.loginContent}>
						<Typography>{`An email link has been sent to ${email}.  Please click the link inside to login to Kick.`}</Typography>
						<Button
							onClick={() => clearEmailSent()}
							variant={"outlined"}
						>
							{"OK"}
						</Button>
					</div>
					: <div className={styles.loginContent}>
						<Typography>{"Enter your email below for passwordless login via an email link"}</Typography>
						<TextField
							label={"Email Address"}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							type={"email"}
							style={{ width: "100%", marginBottom: "1rem" }}
							disabled={context.signingIn || context.loading}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={checked}
									onChange={(e) => setChecked(e.target.checked)}
								/>
							}
							label={
								<Typography variant={"subtitle2"}>
									{`By signing in, you agree to the `}
									<Link
										onClick={() => {
											setChecked(false);
										}}
										to={"/terms"}
										component={RouterLink}
									>{"terms and conditions"}
									</Link>
									{" and the "}
									<Link
										onClick={() => {
											setChecked(false);
										}}
										to={"/privacy"}
										component={RouterLink}
									>{"privacy policy"}
									</Link>
								</Typography>
							}
						/>
						<Button
							variant={"outlined"}
							onClick={() => context.sendEmail(email)}
							disabled={email.trim().length === 0 || context.signingIn || context.loading || !checked}
						>
							{"Login"}
						</Button>
						{
							context.loading &&
							<CircularProgress color={"secondary"} />
						}
					</div>
			}
			<div className={styles.links}>
				<Button
					color={"secondary"}
					startIcon={<Icon>{"help_outline"}</Icon>}
					to={"/about"}
					component={RouterLink}
				>
					{"About"}
				</Button>
			</div>
		</div>
	);
}