import { GetPlaceName } from "../helpers/kick.helpers";

export class Place {
	public lat: number = 0;
	public lon: number = 0;
	public name: string = "";
	public icon: string = "";

	constructor(item?: any) {
		if (!item) return this;
		this.lat = +item.lat;
		this.lon = +item.lon;
		this.icon = item.icon;
		this.name = GetPlaceName(item);
	}
}

export interface OSMPlace {
	place_id: number;
	licence: string;
	osm_type: string;
	osm_id: number;
	boundingbox: string[];
	lat: string;
	lon: string;
	display_name: string;
	class: string;
	type: string;
	importance: number;
	address: Address;
	namedetails: NameDetails;
	error: string;
}

export interface NameDetails {
	name: string;
}

export interface Address {
	[key: string]: string;
	road: string;
	suburb: string;
	city: string;
	municipality: string;
	state: string;
	postcode: string;
	country: string;
	country_code: string;
}
