import { DivIcon, LatLng } from "leaflet";
import { useContext, useEffect } from 'react';
import ReactDOMServer from "react-dom/server";
import { Marker, TileLayer } from "react-leaflet";
import styles from './Map.module.scss';
import { useTheme } from "@mui/material";
import { HomeContext } from "../../../context/home.context";
import { GetMapTheme } from "../../../helpers/kick.helpers";
import { AppContext } from "../../../context/app.context";

export function CurrentLocation(props: { loc: LatLng }) {
	const theme = useTheme();
	return (
		<Marker position={props.loc}
			icon={new DivIcon({
				html: ReactDOMServer.renderToString(
					<div
						className={styles.myLocation}
						style={{ backgroundColor: theme.palette.primary.main }}
					/>)
			})}
		/>

	);
}
export interface IMapTileLayerProps {

}

const MapTileLayer: React.FC<IMapTileLayerProps> = (props) => {
	const appContext = useContext(AppContext);
	const context = useContext(HomeContext);

	useEffect(() => {
		if (appContext.mapMode === "dark") {
			document.documentElement.style.setProperty("--map-tiles-filter", "brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7)");
		} else {
			document.documentElement.style.setProperty("--map-tiles-filter", "none");
		}
	}, [appContext.mapMode]);

	return (
		<>
			<TileLayer className={styles.test} {...GetMapTheme()} />
			{props.children}
			{
				context.myLocation &&
				<CurrentLocation loc={context.myLocation} />
			}
		</>
	);
}

export default MapTileLayer;