import { BottomNavigation, BottomNavigationAction, Icon, Paper } from "@mui/material";
import styles from './Home.module.scss';
import { useState } from "react";
import Map from "./Map/Map";
import HomeProvider from "../../context/home.context";
import { NotFound } from "./NotFound/NotFound";
import Settings from "./Settings/Settings";
import {
	Routes,
	Route,
	Link,
	useLocation
} from "react-router-dom";
import { Events } from "./Events/Events";

function HomeRoot() {
	let location = useLocation();
	const [value, setValue] = useState(location.pathname);

	return (
		<div className={styles.home}>
			<Routes>
				<Route path={"/*"} element={<Map />} />
				<Route path={"settings"} element={<Settings />} />
				<Route path={"events/*"} element={<Events />} />
				<Route path={"*"} element={<NotFound />} />
			</Routes>
			<Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
				<BottomNavigation
					showLabels
					value={value}
					onChange={(e, newValue) => setValue(newValue)}
				>
					<BottomNavigationAction
						label={"Home"}
						icon={<Icon>{"home"}</Icon>}
						to={""}
						value={"/app"}
						component={Link}
					/>
					<BottomNavigationAction
						label={"Events"}
						icon={<Icon>{"event"}</Icon>}
						to={"events"}
						value={"/app/events"}
						component={Link}
					/>
					<BottomNavigationAction
						label={"Settings"}
						icon={<Icon>{"settings"}</Icon>}
						to={"settings"}
						value={"/app/settings"}
						component={Link}
					/>
				</BottomNavigation>
			</Paper>
		</div >
	);
}

function Home() {
	return (
		<HomeProvider>
			<HomeRoot />
		</HomeProvider>
	);
}

export default Home;