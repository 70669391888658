import { User } from "firebase/auth";
import { Filter } from "../models/Filter.model";
import { Kick } from "../models/Kick.model";
import { UserData } from "../models/UserData.model";
import { Sport } from "../models/Sport.model";
import Fetch from "./Fetch";
import { PaletteMode } from "@mui/material";
import { Skill } from "../models/Skill.model";

export default class ApiService extends Fetch {
	public async AddFCMToken(token: string) {
		let item = await this.Post<UserData, string>(`users/addToken`, token);
		return item || null;
	}

	public async RemoveFCMToken(token: string) {
		let item = await this.Post<UserData, string>(`users/removeToken`, token);
		return item || null;
	}

	public async GetUserData() {
		let item = await this.Get<UserData>(`users/data`);
		return item || null;
	}

	public async SetOnboarded() {
		let item = await this.Post<UserData, null>(`users/onboard`, null);
		return item || null;
	}

	public async SetThemeMode(mode: PaletteMode) {
		let item = await this.Post<UserData, PaletteMode>(`users/theme`, mode);
		return item || null;
	}

	public async SetMapMode(mode: PaletteMode) {
		let item = await this.Post<UserData, PaletteMode>(`users/map`, mode);
		return item || null;
	}

	public async GetSports() {
		let items = await this.Get<Sport[]>(`sport`);
		return items || [];
	}

	public async GetSkills() {
		let items = await this.Get<Skill[]>(`skill`);
		return items || [];
	}

	public async GetAttending(ids: string[]) {
		let items = await this.Post<User[], string[]>(`users`, ids);
		return items || [];
	}

	public async GetFilteredEvents(filter: Filter) {
		let items = await this.Post<Kick[], Filter>(`kick/search`, filter);
		return items ? items.map(i => new Kick(i)) : [];
	}

	public async GetMyCreatedEvents() {
		let items = await this.Get<Kick[]>(`kick/created`);
		return items ? items.map(i => new Kick(i)) : [];
	}

	public async GetMyUpcomingEvents() {
		let items = await this.Get<Kick[]>(`kick/upcoming`);
		return items ? items.map(i => new Kick(i)) : [];
	}

	public async GetParticipatedEvents() {
		let items = await this.Get<Kick[]>(`kick/participated`);
		return items ? items.map(i => new Kick(i)) : [];
	}

	public async CreateKick(item: Kick) {
		let newItem = await this.Post<Kick>(`kick`, item);
		return new Kick(newItem);
	}

	public async GetKickById(kickId: string) {
		let item = await this.Get<Kick>(`kick?kickId=${kickId}`);
		return new Kick(item);
	}

	public async UpdateKick(kick: Kick) {
		let item = await this.Put<Kick>(`kick/${kick.id}`, kick);
		return new Kick(item);
	}

	public async DeleteKick(kickId: string) {
		await this.Delete<void>(`kick/${kickId}`);
	}

	public async ToggleAttending(kickId: string) {
		let item = await this.Post<Kick, null>(`kick/attending?kickId=${kickId}`, null);
		return new Kick(item);
	}
}