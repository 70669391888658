import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, TextField } from "@mui/material";
import * as React from "react";

export interface IAlertProps {

}

/**
 * Types of alerts available to consume
 */
export interface IAlertContext extends IAlertProps {
	/**
	 * Display info only with OK button to dismiss
	 * @param title 
	 * @param text 
	 */
	Alert(title: string, text: string): Promise<void>;
	/**
	 * Present user with `OK` or `Cancel` options
	 * @param title 
	 * @param text 
	 */
	Confirm(title: string, text: string): Promise<boolean>;
	/**
	 * Ask user for text input
	 * @param title 
	 * @param text 
	 */
	Prompt(title: string, text: string): Promise<string>;
}

export const AlertContext = React.createContext<IAlertContext>(
	{ ...{} as IAlertContext }
);

type AlertType = "ALERT" | "CONFIRM" | "PROMPT";

/**
 * Context Provider that allows consumption of alert modals to present to the user
 * 
 * Wrap required component in provider to allow consuption by child components
 * @param props 
 * @returns 
 */
export const AlertProvider: React.FC<IAlertProps> = (props) => {
	const [open, setOpen] = React.useState(false);
	const [value, setValue] = React.useState("");
	const [titleText, setTitleText] = React.useState("");
	const [contentText, setContentText] = React.useState("");
	const [type, setType] = React.useState<AlertType>();
	/**
	 * Holds the response to return to the user once they have actioned the modal
	 */
	const returnResponse = React.useRef<(value: boolean | string | null | void) => void>();

	/**
	 * Create the promise and await a response to then return to the caller
	 * @param alertType 
	 * @param title 
	 * @param text 
	 * @returns 
	 */
	async function Process(alertType: AlertType, title: string, text: string) {
		setOpen(true);
		setType(alertType);
		setTitleText(title);
		setContentText(text);
		const userAction = new Promise(resolve => {
			returnResponse.current = resolve;
		});
		const result = await userAction;
		setOpen(false);
		setValue("");
		return result;
	}

	/**
	 * Return a positive (OK) response on promise fulfilled
	 */
	function confirmResponse() {
		switch (type) {
			case "ALERT":
				returnResponse.current?.();
				break;
			case "CONFIRM":
				returnResponse.current?.(true);
				break;
			case "PROMPT":
				returnResponse.current?.(value);
				break;

			default:
				returnResponse.current?.();
				break;
		}
	}

	/**
	 * Return a negative (Cancel) response on promise fulfilled
	 */
	function cancelResponse() {
		switch (type) {
			case "CONFIRM":
				returnResponse.current?.(false);
				break;
			case "PROMPT":
				returnResponse.current?.(null);
				break;

			default:
				returnResponse.current?.();
				break;
		}
	}

	return (
		<AlertContext.Provider
			value={{
				...props,
				Alert: (t, c) => Process("ALERT", t, c) as Promise<void>,
				Confirm: (t, c) => Process("CONFIRM", t, c) as Promise<boolean>,
				Prompt: (t, c) => Process("PROMPT", t, c) as Promise<string>,
			}}
		>
			{props.children}
			<Dialog
				open={open}
			>
				<DialogTitle id="alert-dialog-title">
					{titleText}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{contentText}
					</DialogContentText>
					{
						type === "PROMPT" &&
						<TextField
							autoFocus={true}
							value={value}
							onChange={(e) => setValue(e.target.value)}
							autoComplete={"off"}
							onKeyDown={(e) => e.key === "Enter" ? confirmResponse() : null}
						/>
					}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => cancelResponse()}
						startIcon={<Icon>{"close"}</Icon>}
					>
						{"Cancel"}
					</Button>
					<Button
						autoFocus
						onClick={() => confirmResponse()}
						startIcon={<Icon>{"done"}</Icon>}
					>
						{"OK"}
					</Button>
				</DialogActions>
			</Dialog>
		</AlertContext.Provider>
	);
};
