export class EnvConfig {
	public static get ApiUrl() {
		const url = process.env.NODE_ENV === "development"
			? process.env.REACT_APP_DEV_API_URL
			: process.env.REACT_APP_API_URL;
		return url || "";
	}

	public static get OSMUrl() {
		return "https://nominatim.openstreetmap.org/";
	}

	public static get Version() {
		return process.env.REACT_APP_VERSION;
	}

	public static get VapidKey() {
		return process.env.REACT_APP_VAPID_KEY;
	}

	public static get BugsnagKey() {
		return process.env.REACT_APP_BUGSNAG_KEY;
	}
}