import { useEffect, useState } from "react";

interface AdsWindow extends Window {
	adsbygoogle: any;
}
type GlobalWindow = AdsWindow & typeof globalThis;

export function Ad() {
	const [hasError, setHasError] = useState(false);
	useEffect(() => {
		try {
			const adsWindow = window as GlobalWindow;
			(adsWindow.adsbygoogle = adsWindow.adsbygoogle || []).push({});
		} catch (error) {
			setHasError(true);
			console.log(error as Error);
		}
	}, []);

	return (
		<>
			<ins
				className={"adsbygoogle"}
				style={{ display: "block" }}
				data-ad-client={"ca-pub-4874719043014694"}
				data-ad-slot={"4215382534"}
				data-ad-format={"horizontal"}
				data-full-width-responsive={"true"}
				data-adtest={process.env.NODE_ENV === "production" ? undefined : "on"}
			/>
			{
				hasError &&
				<div>{"Error loading ad"}</div>
			}
		</>
	);
}