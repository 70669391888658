import { Avatar, Button, CircularProgress, Icon, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import { updateProfile } from "firebase/auth";
import { useContext, useMemo, useState } from "react";
import { AppContext } from "../../../context/app.context";
import styles from './Settings.module.scss';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import Compressor from 'compressorjs';

export interface IUpdateProfileProps {
	onClose(): void;
}

const Input = styled('input')({
	display: 'none',
});

function UpdateProfile(props: IUpdateProfileProps) {
	const appContext = useContext(AppContext);
	const [name, setName] = useState(appContext.user?.displayName || "");
	const [file, setFile] = useState<File>();
	const [loading, setLoading] = useState(false);

	const previewUrl = useMemo(() => {
		if (file) {
			const url = URL.createObjectURL(file);
			return url;
		} else {
			return appContext.user?.photoURL;
		}
	}, [file, appContext.user?.photoURL]);

	const dirty = useMemo(() => {
		return (
			name.trim() !== appContext.user?.displayName ||
			file !== undefined
		)
	}, [name, file, appContext.user?.displayName]);

	async function uploadProfilePhoto() {
		let url: string | undefined = undefined;
		if (file) {
			const storage = getStorage();
			const imageRef = ref(storage, `profileImages/${appContext.user?.uid}/image`);
			const result = await uploadBytes(imageRef, file, { contentType: file.type });
			url = await getDownloadURL(result.ref);
		}
		return url;
	}

	async function updateUserProfile() {
		setLoading(true);
		try {
			const url = await uploadProfilePhoto();
			await updateProfile(appContext.user!, {
				displayName: name.trim(),
				photoURL: url
			});
			props.onClose();
		} catch (error) {
			appContext.logError(error as Error);
		}
		setLoading(false);
	}

	return (
		appContext.user
			? <div className={styles.settingsContent}>
				<div className={styles.content}>
					{
						previewUrl
							? <Avatar
								alt={"User Profile Photo"}
								src={previewUrl}
								sx={{ width: 64, height: 64 }}
							/>
							: <Icon>{"account_circle"}</Icon>
					}
					<label htmlFor={"icon-button-file"}>
						<Input
							id={"icon-button-file"}
							accept={"image/*"}
							type={"file"}
							onChange={(e) => {
								if (e.target.files?.length) {
									new Compressor(e.target.files[0], {
										quality: 1,
										resize: "cover",
										width: 64,
										height: 64,
										success: (compressedImage) => setFile(compressedImage as File),
										error: (error) => {
											appContext.logError(error as Error);
											setFile(undefined);
										}
									});
								}
							}}
						/>
						<Button
							color={"primary"}
							aria-label={"upload picture"}
							component={"span"}
							startIcon={<Icon>{"photo_camera"}</Icon>}
						>
							{"Change photo"}
						</Button>
					</label>
					<TextField
						label={"Display Name"}
						value={name}
						onChange={(e) => setName(e.target.value)}
						disabled={loading}
					/>
				</div>
				{
					loading &&
					<CircularProgress color={"secondary"} />
				}
				<Button
					variant={"outlined"}
					color={"success"}
					onClick={async () => updateUserProfile()}
					startIcon={<Icon>{"save"}</Icon>}
					disabled={!dirty || loading}
				>
					{"Update"}
				</Button>
				<Button
					variant={"outlined"}
					onClick={() => props.onClose()}
					startIcon={<Icon>{"close"}</Icon>}
					disabled={loading}
				>
					{"cancel"}
				</Button>
			</div>
			: <div>{"No user found"}</div>
	);
}

export default UpdateProfile;