import React, { useContext, useEffect, useMemo, useState } from "react";
import { EnvConfig } from "../config/env.config";
import { Kick, KickStatus } from "../models/Kick.model";
import ApiService from "../services/ApiService";
import OpenStreetMapService from "../services/OpenStreetMapService";
import { LatLng, LatLngBounds } from "leaflet";
import { Place } from "../models/Place.model";
import { Sport } from "../models/Sport.model";
import { AppContext } from "./app.context";
import { User } from "firebase/auth";
import { Filter } from "../models/Filter.model";
import { Skill } from "../models/Skill.model";

export interface IHomeContext {
	loading: boolean;
	sports: Sport[];
	skills: Skill[];
	kicks: Kick[];
	getKicksInBounds(): Promise<void>;
	getKickById(id: string): Promise<Kick | undefined>;
	createKick(kick: Kick): Promise<Kick>;
	updateKick(kick: Kick): Promise<Kick>;
	deleteKick(id: string): Promise<void>;
	filter: Filter;
	setFilter(filter: Filter): void;
	searchOSM(query: string, bounds: string): Promise<Place[]>;
	onBoundsChanged(bounds: LatLngBounds): void;
	latLng?: LatLng;
	myLocation?: LatLng;
	setMyLocation(loc: LatLng): void;
	setLatLng(latlng?: LatLng): void;
	zoom: number;
	setZoom(zoom: number): void;
	reverseGeocode(latlng: LatLng): Promise<string>;
	toggleAttending(id: string): Promise<Kick | undefined>;
	getAttending(ids: string[]): Promise<User[]>;
	getMyCreatedEvents(): Promise<Kick[]>;
	getMyUpcomingEvents(): Promise<Kick[]>;
	getParticipatedEvents(): Promise<Kick[]>;
}

export const HomeContext = React.createContext<IHomeContext>(
	{ ...{} as IHomeContext }
);

const HomeProvider: React.FC = (props) => {
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [kicks, setKicks] = useState<Kick[]>([]);
	const [sports, setSports] = useState<Sport[]>([]);
	const [skills, setSkills] = useState<Skill[]>([]);
	const [latLng, setLatLng] = useState<LatLng>();
	const [myLocation, setMyLocation] = useState<LatLng>();
	const [zoom, setZoom] = useState(15);
	const [filter, setFilter] = useState(new Filter());

	const osm = useMemo(() => {
		return new OpenStreetMapService(appContext.user!, EnvConfig.OSMUrl);
	}, [appContext.user]);

	const api = useMemo(() => {
		return new ApiService(appContext.user!, EnvConfig.ApiUrl);
	}, [appContext.user]);

	useEffect(() => {
		async function getSports() {
			setLoading(true);
			let items: Sport[] = [];
			try {
				items = await api.GetSports();
			} catch (error) {
				appContext.logError(error as Error);
			}
			setSports(items);
			setLoading(false);
		}
		async function getSkills() {
			setLoading(true);
			let items: Skill[] = [];
			try {
				items = await api.GetSkills();
			} catch (error) {
				appContext.logError(error as Error);
			}
			setSkills(items);
			setLoading(false);
		}
		if (appContext.user) {
			getSports();
			getSkills();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api, appContext.user]);

	useEffect(() => {
		if (appContext.user && filter)
			getKicksInBounds();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter, api, appContext.user]);

	async function getKicksInBounds() {
		setLoading(true);
		try {
			let items = await api.GetFilteredEvents(filter);
			setKicks([...items]);
		} catch (error) {
			appContext.logError(error as Error);
		}
		setLoading(false);
	}

	async function getKickById(id: string) {
		setLoading(true);
		let item: Kick | undefined = undefined;
		try {
			item = await api.GetKickById(id);
		} catch (error) {
			appContext.logError(error as Error);
		}
		setLoading(false);
		return item;
	}

	async function createKick(kick: Kick) {
		setLoading(true);
		try {
			kick.creator = appContext.user!.uid;
			kick.status = KickStatus.ACTIVE;
			kick.attending = [appContext.user!.uid];
			kick = await api.CreateKick(kick);
		} catch (error) {
			appContext.logError(error as Error);
		}
		setLoading(false);
		return kick;
	}

	async function updateKick(kick: Kick) {
		setLoading(true);
		try {
			kick = await api.UpdateKick(kick);
		} catch (error) {
			appContext.logError(error as Error);
		}
		setLoading(false);
		return kick;
	}

	async function deleteKick(id: string) {
		setLoading(true);
		try {
			await api.DeleteKick(id);
		} catch (error) {
			appContext.logError(error as Error);
		}
		setLoading(false);
	}

	async function toggleAttending(id: string) {
		setLoading(true);
		let item: Kick | undefined = undefined;
		try {
			item = await api.ToggleAttending(id);
		} catch (error) {
			appContext.logError(error as Error);
		}
		setLoading(false);
		return item;
	}

	async function getMyCreatedEvents() {
		setLoading(true);
		let items: Kick[] = [];
		try {
			items = await api.GetMyCreatedEvents();
		} catch (error) {
			appContext.logError(error as Error);
		}
		setLoading(false);
		return items;
	}

	async function getMyUpcomingEvents() {
		setLoading(true);
		let items: Kick[] = [];
		try {
			items = await api.GetMyUpcomingEvents();
		} catch (error) {
			appContext.logError(error as Error);
		}
		setLoading(false);
		return items;
	}

	async function getParticipatedEvents() {
		setLoading(true);
		let items: Kick[] = [];
		try {
			items = await api.GetParticipatedEvents();
		} catch (error) {
			appContext.logError(error as Error);
		}
		setLoading(false);
		return items;
	}

	async function searchOSM(text: string, bounds: string) {
		setLoading(true);
		let places: Place[] = [];
		try {
			if (!text.trim() || !bounds) return [];
			places = await osm.Search(text, bounds);
		} catch (error) {
			appContext.logError(error as Error);
		}
		setLoading(false);
		return places;
	}

	async function reverseGeocode(latlng: LatLng) {
		let name = "";
		try {
			name = await osm.ReverseGeocode(latlng);
		} catch (error) {
			appContext.logError(error as Error);
		}
		return name;
	}

	async function getAttending(ids: string[]) {
		setLoading(true);
		let items: User[] = [];
		try {
			items = await api.GetAttending(ids);
		} catch (error) {
			appContext.logError(error as Error);
		}
		setLoading(false);
		return items;
	}

	function getLocation() {
		navigator.geolocation.watchPosition(
			({ coords }) => {
				const loc = new LatLng(coords.latitude, coords.longitude);
				setMyLocation(loc);
			},
			(error) => appContext.logError(new Error(error.message)),
			{ enableHighAccuracy: true, maximumAge: 5000, timeout: 10000 }
		);

		navigator.geolocation.getCurrentPosition(
			({ coords }) => {
				const loc = new LatLng(coords.latitude, coords.longitude);
				setLatLng(loc);
			},
			(error) => appContext.logError(new Error(error.message)),
			{ enableHighAccuracy: true }
		);
	}

	useEffect(() => {
		async function checkLocationPermission() {
			const result = await navigator.permissions.query({ name: "geolocation" });
			if (result.state === 'granted') {
				getLocation();
			} else if (result.state === 'prompt') {
				getLocation();
			} else if (result.state === 'denied') {
				const loc = new LatLng(0, 0);
				setLatLng(loc);
				setZoom(1);
				setMyLocation(undefined);
				appContext.showSnack("Location denied, enable in browser to see location", "info");
			}
			result.onchange = (e) => checkLocationPermission();
		}
		if (navigator.permissions) {
			checkLocationPermission();
		} else {
			getLocation();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function onBoundsChanged(bounds: LatLngBounds) {
		let bboxString = bounds.toBBoxString();
		if (bboxString !== filter.boundsString) {
			filter.boundsString = bboxString;
			setFilter({ ...filter });
		}
	}

	return (
		<HomeContext.Provider value={{
			...props,
			loading,
			sports,
			skills,
			kicks,
			filter,
			setFilter,
			getKicksInBounds,
			getKickById,
			createKick,
			updateKick,
			deleteKick,
			searchOSM,
			onBoundsChanged,
			latLng,
			setLatLng,
			myLocation,
			setMyLocation,
			zoom,
			setZoom,
			reverseGeocode,
			toggleAttending,
			getAttending,
			getMyCreatedEvents,
			getMyUpcomingEvents,
			getParticipatedEvents
		}}>
			{props.children}
		</HomeContext.Provider>
	);
}

export default HomeProvider;