import { AppBar, Avatar, Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Icon, IconButton, Link, ListItem, ListItemAvatar, ListItemText, Slide, Toolbar, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../../context/app.context";
import { HomeContext } from "../../../context/home.context";
import { User } from "firebase/auth";
import { TransitionProps } from "@mui/material/transitions";
import { MapContainer, Marker } from "react-leaflet";
import styles from "./ViewKick.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Kick } from "../../../models/Kick.model";
import { Link as RouterLink } from "react-router-dom";
import { grey } from "@mui/material/colors";
import { getEventDateString } from "../../../helpers/kick.helpers";
import Share from "../../Share/Share";
import { format } from "date-fns";
import { Ad } from "../../Ad/Ad";
import MapTileLayer from "../Map/MapTileLayer";
import { HelpIcon } from "../../HelpIcon/HelpIcon";

export interface IViewKickProps {
	referrer: string;
}

export const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function ViewKick(props: IViewKickProps) {
	const appContext = useContext(AppContext);
	const context = useContext(HomeContext);
	const [kick, setKick] = useState<Kick>();
	const [attending, setAttending] = useState<User[]>([]);
	const navigate = useNavigate();
	const params = useParams<"kickId">();

	function onClose() {
		setKick(undefined);
		setAttending([]);
		navigate(props.referrer);
	}

	useEffect(() => {
		async function getKick() {
			const item = await context.getKickById(params.kickId!);
			setKick(item);
		}
		if (params.kickId && (!kick || params.kickId !== kick.id)) {
			getKick();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.kickId]);

	useEffect(() => {
		async function getAttending() {
			if (kick && kick.attending.length > 0) {
				const items = await context.getAttending(kick.attending);
				setAttending([...items]);
			} else {
				setAttending([]);
			}
		}
		getAttending();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [kick?.attending])

	const sport = useMemo(() => {
		return context.sports.find(i => i.id === kick?.sport);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [kick?.sport, context.sports]);

	const skill = useMemo(() => {
		const items = context.skills.filter(i => kick?.skill.some(s => s === i.id));
		return items.map(i => i.name).join(", ");

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [kick?.skill, context.skills]);

	const date = useMemo(() => {
		return getEventDateString(kick);
	}, [kick]);

	const creator = useMemo(() => {
		return attending.find(i => i.uid === kick?.creator);
	}, [kick, attending]);

	const userIsAttending = useMemo(() => {
		if (!appContext.user) return false;
		return attending.some(i => i.uid === appContext.user!.uid);
	}, [appContext.user, attending]);

	const iCal = useMemo(() => {
		const data = encodeURI(
			`BEGIN:VCALENDAR\n` +
			`VERSION:2.0\n` +
			`PRODID:-//kick.inprod.dev//Kick//EN\n` +
			`BEGIN:VEVENT\n` +
			`SUMMARY:${kick?.name}\n` +
			`UID:${kick?.id}\n` +
			`DTSTART:${format(kick?.start ? kick?.start : new Date(), "yyyyMMddhhmmss'Z'")}\n` +
			`DTEND:${format(kick?.end ? kick?.end : new Date(), "yyyyMMddhhmmss'Z'")}\n` +
			`DTSTAMP:${format(new Date(), "yyyyMMddhhmmss'Z'")}\n` +
			`LOCATION:${kick?.locationName}\n` +
			`GEO:${kick?.location.coordinates[1]};${kick?.location.coordinates[0]}\n` +
			`DESCRIPTION:${kick?.description}\n` +
			`END:VEVENT\n` +
			`END:VCALENDAR`
		);
		return (`data:text/calendar;charset=utf8,${data}`);
	}, [kick]);

	return (
		<Dialog
			fullScreen={appContext.isMobile}
			open={kick !== undefined}
			onClose={() => onClose()}
			TransitionComponent={Transition}
			scroll={"paper"}
			PaperProps={{
				style: { maxHeight: "100vh" }
			}}
			classes={{ container: styles.dialogContainer }}
		>
			<div
				className={styles.container}
				style={{ minWidth: appContext.isMobile ? "100%" : 600 }}
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<IconButton
							edge={"start"}
							color={"inherit"}
							onClick={() => onClose()}
							aria-label={"close"}
						>
							<Icon>{"close"}</Icon>
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							{"Event"}
						</Typography>
					</Toolbar>
				</AppBar>
				{
					kick &&
					<>
						<MapContainer
							center={{
								lat: kick.location.coordinates[1],
								lng: kick.location.coordinates[0]
							}}
							zoom={16}
							style={{ height: "100%", zIndex: 0 }}
							zoomControl={false}
						>
							<MapTileLayer>
								<Marker
									position={{
										lat: kick.location.coordinates[1],
										lng: kick.location.coordinates[0]
									}}
								/>
							</MapTileLayer>
						</MapContainer>
						<DialogContent>
							<div className={styles.details}>
								<Typography variant={"h4"}>{kick.name ? kick.name : "Event"}</Typography>
								<div className={styles.content}>
									<Tooltip arrow placement={"right"} title={"Start and End time"}>
										<Icon>{"event"}</Icon>
									</Tooltip>
									<Link variant={"caption"} target={"_blank"} href={iCal}>{date}</Link>
									<HelpIcon title={"Clicking this link will download an iCal file to add the event to your calendar"} />
								</div>
								<div className={styles.content}>
									<Tooltip arrow placement={"right"} title={"Sport"}>
										<Icon>{sport?.icon}</Icon>
									</Tooltip>
									<Typography>{sport?.name}</Typography>
								</div>
								<div className={styles.content}>
									<Tooltip arrow placement={"right"} title={"Anticipated skill level"}>
										<Icon>{"trending_up"}</Icon>
									</Tooltip>
									<Typography>{skill || "Unknown"}</Typography>
								</div>
								<div className={styles.content}>
									<Tooltip arrow placement={"right"} title={"Location"}>
										<Icon>{"map"}</Icon>
									</Tooltip>
									<Link
										target={"_blank"}
										href={`https://www.google.com/maps/dir/Current+location/${kick.location.coordinates[1]},${kick.location.coordinates[0]}`}
									>
										{kick.locationName}
									</Link>
									<HelpIcon title={"Clicking the link will use Google maps to get directions to the event"} />
								</div>
								<div className={styles.content}>
									<Tooltip arrow placement={"right"} title={"Creator"}>
										<Icon>{"person"}</Icon>
									</Tooltip>
									<Typography>{creator?.displayName}</Typography>
								</div>
								{
									(appContext.user?.uid !== kick.creator || process.env.NODE_ENV === "development") &&
									<Button
										variant={"outlined"}
										color={userIsAttending ? "warning" : "success"}
										onClick={async () => {
											let item = await context.toggleAttending(kick.id);
											if (item)
												setKick({ ...item });
										}}
										startIcon={<Icon>{userIsAttending ? "thumb_down" : "thumb_up"}</Icon>}
										disabled={context.loading}
									>
										{userIsAttending ? "Mark not attending" : "Mark Attending"}
									</Button>
								}
							</div>
							<Divider />
							<div className={styles.details}>
								<Typography variant={"h6"}>{"Description"}</Typography>
								<Typography variant={"body2"}>{kick.description}</Typography>
							</div>
							<Divider />
							<div className={styles.details}>
								<Typography variant={"h6"}>{"Attending"}</Typography>
								<div>
									{
										attending.map((item, index) =>
											<ListItem key={index}>
												<ListItemAvatar>
													<Avatar src={item.photoURL || undefined} alt={item.displayName || undefined} />
												</ListItemAvatar>
												<ListItemText
													primary={item.displayName}
												/>
											</ListItem>
										)
									}
									{
										attending.length === 0 &&
										<Typography variant={"body2"} color={grey[600]}>{"No attendees yet"}</Typography>
									}
								</div>
								{
									context.loading &&
									<CircularProgress style={{ justifySelf: "center" }} color={"secondary"} />
								}
							</div>
							<Ad />
						</DialogContent>
					</>
				}
			</div>
			{
				kick &&
				<DialogActions>
					<Share kick={kick} />
					{
						kick.creator === appContext.user!.uid &&
						<Button
							color={"secondary"}
							startIcon={<Icon>{"edit"}</Icon>}
							to={`edit`}
							component={RouterLink}
						>
							{"Edit"}
						</Button>
					}
				</DialogActions>
			}
		</Dialog>
	);
}

export default ViewKick;