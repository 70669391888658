import { AppBar, Icon, IconButton, Toolbar, Typography } from "@mui/material";
import TermsSVG from "../../../assets/svg/terms.svg";

export function Terms() {
	return (
		<div>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<IconButton
						edge={"start"}
						color={"inherit"}
						onClick={() => window.history.back()}
						aria-label={"close"}
					>
						<Icon>{"arrow_back"}</Icon>
					</IconButton>
					<Typography sx={{ ml: 2, flex: 1 }} variant={"h6"} component={"div"}>
						{"Terms"}
					</Typography>
				</Toolbar>
			</AppBar>
			<div style={{
				display: "grid",
				gap: "1rem",
				padding: "1rem",
				justifyItems: "center",
				textAlign: "center"
			}}>
				<Typography>{"Placeholder content for the Kick terms and conditions."}</Typography>
				<img src={TermsSVG} alt={"Terms SVG"} style={{ width: 200, maxWidth: "90%", padding: "1rem" }} />
				<Typography>{"Only to be used for the creation of public sporting events."}</Typography>
				<Typography>{"Do not post inappropriate content."}</Typography>
				<Typography>{"Events are public and not moderated by Kick or Insignificant Productions so participation is at your own risk."}</Typography>
				<Typography>{"Just be nice to each other and don't do anything silly."}</Typography>
			</div>
		</div>
	);
}