import { Avatar, Button, CircularProgress, Dialog, DialogContent, Divider, Icon, MobileStepper, Slide, styled, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../../context/app.context";
import { TransitionProps } from "@mui/material/transitions";
import styles from "./Onboarding.module.scss";
import { useNavigate } from "react-router-dom";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { updateProfile } from "firebase/auth";
import Compressor from "compressorjs";
import StartSVG from "../../../assets/svg/nature.svg";
import NameSVG from "../../../assets/svg/onboarding-name.svg";
import PhotoSVG from "../../../assets/svg/onboarding-photo.svg";
import FinishSVG from "../../../assets/svg/onboarding-finish.svg";

export interface IOnboardingProps {

}

const Input = styled('input')({
	display: 'none',
});

export const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function Onboarding(props: IOnboardingProps) {
	const context = useContext(AppContext);
	const navigate = useNavigate();
	const [activeStep, setActiveStep] = useState(0);
	const [name, setName] = useState(context.user?.displayName || "");
	const [file, setFile] = useState<File>();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (context.userData && context.userData.onboarded) {
			navigate("/app");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.userData]);

	const previewUrl = useMemo(() => {
		if (file) {
			const url = URL.createObjectURL(file);
			return url;
		} else {
			return context.user?.photoURL;
		}
	}, [file, context.user?.photoURL]);

	async function uploadProfilePhoto() {
		let url: string | undefined = undefined;
		if (file) {
			const storage = getStorage();
			const imageRef = ref(storage, `profileImages/${context.user?.uid}/image`);
			const result = await uploadBytes(imageRef, file, { contentType: file.type });
			url = await getDownloadURL(result.ref);
		}
		return url;
	}

	async function updateUserProfile() {
		setLoading(true);
		try {
			const url = await uploadProfilePhoto();
			await updateProfile(context.user!, {
				displayName: name.trim(),
				photoURL: url
			});
			await context.setOnboarded();
		} catch (error) {
			context.logError(error as Error);
		}
		setLoading(false);
	}

	const profileComplete = useMemo(() => {
		if (context.user) {
			return (
				name.trim().length > 0 &&
				(
					file !== undefined ||
					(context.user.photoURL ? context.user.photoURL.trim().length > 0 : false)
				)
			);
		}
		return false;
	}, [context.user, file, name]);

	const nextDisabled = useMemo(() => {
		switch (activeStep) {
			case 0:
				return false;
			case 1:
				return name.trim().length === 0;
			case 2:
				return (file === undefined && context.user?.photoURL?.trim().length === 0);
			case 3:
				return !profileComplete;
			default:
				return true;
		}
	}, [activeStep, file, name, profileComplete, context.user?.photoURL]);

	return (
		<Dialog
			fullScreen={context.isMobile}
			open={true}
			TransitionComponent={Transition}
			scroll={"paper"}
			fullWidth
		>
			<DialogContent>
				<div
					className={styles.container}
				>
					{
						activeStep === 0 &&
						<>
							<img src={StartSVG} alt={"Onboarding Start SVG"} className={styles.svg} />
							<Typography>{"Welcome to kick, let's setup your profile!"}</Typography>
						</>
					}
					{
						activeStep === 1 &&
						<>
							<img src={NameSVG} alt={"Onboarding Name SVG"} className={styles.svg} />
							<Typography>{"Your display name and profile photo will be shown when you create or attend an event"}</Typography>
							<Typography>{"Enter a display name"}</Typography>
							<TextField
								value={name}
								placeholder={"Display Name"}
								onChange={(e) => setName(e.target.value)}
								disabled={loading}
							/>
						</>
					}
					{
						activeStep === 2 &&
						<>
							<img src={PhotoSVG} alt={"Onboarding ProfilePhoto SVG"} className={styles.svg} />
							<Typography>{"Select a profile photo"}</Typography>
							{
								previewUrl
									? <Avatar
										alt={"User Profile Photo"}
										src={previewUrl}
										sx={{ width: 64, height: 64 }}
									/>
									: <Icon>{"account_circle"}</Icon>
							}
							<label htmlFor={"icon-button-file"}>
								<Input
									id={"icon-button-file"}
									accept={"image/*"}
									type={"file"}
									onChange={(e) => {
										if (e.target.files?.length) {
											new Compressor(e.target.files[0], {
												quality: 1,
												resize: "cover",
												width: 64,
												height: 64,
												success: (compressedImage) => setFile(compressedImage as File),
												error: (error) => {
													context.logError(error as Error);
													setFile(undefined);
												}
											});
										}
									}}
								/>
								<Button
									color={"primary"}
									aria-label={"upload picture"}
									component={"span"}
									startIcon={<Icon>{"photo_camera"}</Icon>}
								>
									{"Change photo"}
								</Button>
							</label>
						</>
					}
					{
						activeStep === 3 &&
						<>
							<img src={FinishSVG} alt={"Onboarding Finish SVG"} className={styles.svg} />
							<Typography>{"Press finish to update your profile"}</Typography>
							<Typography>{"Visit the settings page to update your profile at any time, change the app theme or enable notifications"}</Typography>
							{
								loading &&
								<CircularProgress />
							}
						</>
					}
				</div>
			</DialogContent>
			<Divider />
			<MobileStepper
				variant={"dots"}
				steps={4}
				position={"static"}
				activeStep={activeStep}
				nextButton={
					activeStep === 3
						? <Button
							size={"small"}
							onClick={() => updateUserProfile()}
							disabled={!profileComplete}
						>
							{"Finish"}
						</Button>
						: <Button
							size={"small"}
							onClick={() => setActiveStep((s) => s + 1)}
							disabled={nextDisabled}
						>
							{"Next"}
							<Icon>{"keyboard_arrow_right"}</Icon>
						</Button>
				}
				backButton={
					<Button
						size={"small"}
						onClick={() => setActiveStep((s) => s - 1)}
						disabled={activeStep === 0}
					>
						<Icon>{"keyboard_arrow_left"}</Icon>
						{"Back"}
					</Button>
				}
			/>
		</Dialog>
	);
}

export default Onboarding;