import { Button, Icon, Typography, AppBar, Toolbar, Avatar, ListItem, ListItemAvatar, ListItemText, FormControlLabel, Switch } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/app.context";
import { AlertContext } from "../../Alert/Alert.context";
import styles from './Settings.module.scss';
import UpdateProfile from "./UpdateProfile";
import { Link } from "react-router-dom";
import { EnvConfig } from "../../../config/env.config";
import InstallPrompt from "../../../InstallPrompt/InstallPrompt";
import { isSupported } from "firebase/messaging";
import { Ad } from "../../Ad/Ad";
import { AppThemeContext } from "../../../context/appTheme.context";
import { MaterialUISwitch } from "./MaterialUISwitch";

function Settings() {
	const Alert = useContext(AlertContext);
	const context = useContext(AppContext);
	const theme = useContext(AppThemeContext);
	const [editMode, setEditMode] = useState(false);
	const [notificationsSupported, setNotificationsSupported] = useState(false);
	const [token, setToken] = useState<string | null>(null);

	async function getIsSupported() {
		const result = await isSupported();
		setNotificationsSupported(result);
		return result;
	}

	async function getToken() {
		if (Notification.permission === "granted") {
			const result = await context.getFCMToken();
			setToken(result);
		}
	}

	useEffect(() => {
		async function setupNotifications() {
			const supported = await getIsSupported();
			if (supported)
				getToken();
		}
		setupNotifications();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.userData]);

	return (
		<div className={styles.settings} style={{ maxHeight: "100vh" }}>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<Typography sx={{ ml: 2, flex: 1 }} variant={"h6"} component={"div"}>
						{editMode ? "Update Profile" : "Settings"}
					</Typography>
				</Toolbar>
			</AppBar>
			<div className={styles.settingsContainer} style={{ maxHeight: "100vh", width: "100vw" }}>
				{
					editMode
						? <UpdateProfile
							onClose={() => setEditMode(false)}
						/>
						: <div className={styles.settingsContent}>
							<div className={styles.content}>
								{
									context.user?.photoURL
										? <ListItem>
											<ListItemAvatar>
												<Avatar src={context.user.photoURL || undefined} alt={context.user.displayName || undefined} />
											</ListItemAvatar>
											<ListItemText
												primary={context.user.displayName}
											/>
										</ListItem>
										: <Icon>{"account_circle"}</Icon>
								}
								<Button
									onClick={() => setEditMode(true)}
									startIcon={<Icon>{"edit"}</Icon>}
								>
									{"Edit Profile"}
								</Button>
								<Button
									color={"warning"}
									variant={"outlined"}
									onClick={async () => {
										if (await Alert.Confirm("Sign Out?", "Are you sure you want to sign out?")) {
											context.signOutUser();
										}
									}}
									startIcon={<Icon>{"logout"}</Icon>}
								>
									{"Sign Out"}
								</Button>
							</div>
							<div style={{ display: "grid", justifyItems: "center", textAlign: "center", gap: "0.5rem" }}>
								<FormControlLabel
									control={<MaterialUISwitch sx={{ m: 1 }} checked={theme.themeMode === "dark"} onChange={() => theme.toggleThemeMode()} />}
									label={"App Theme"}
								/>
								<FormControlLabel
									control={<MaterialUISwitch sx={{ m: 1 }} checked={context.mapMode === "dark"} onChange={() => context.toggleMapMode()} />}
									label={"Map Theme"}
								/>
								<FormControlLabel
									control={
										<Switch
											sx={{ m: 1 }}
											checked={(token && context.userData) ? context.userData.tokens.includes(token) : false}
											onChange={async (e, checked) => {
												if (checked) {
													const newToken = await context.addFCMToken();
													setToken(newToken);
												} else {
													await context.removeFCMToken();
													setToken(null);
												}
											}}
										/>
									}
									label={"Notifications"}
									disabled={!notificationsSupported || Notification.permission === "denied"}
								/>
								{
									!notificationsSupported &&
									<Typography variant={"caption"}>{"Notifications not supported"}</Typography>
								}
								{
									notificationsSupported && Notification.permission === "denied" &&
									<Typography variant={"caption"}>{"Please enable the 'Notifications' permission in your browser to receive notifications"}</Typography>
								}
							</div>
							<div style={{ display: "grid", justifyItems: "center", textAlign: "center", gap: "0.5rem" }}>
								<Button
									color={"secondary"}
									startIcon={<Icon>{"verified_user"}</Icon>}
									to={"/privacy"}
									component={Link}
								>
									{"Privacy"}
								</Button>
								<Button
									color={"secondary"}
									startIcon={<Icon>{"gavel"}</Icon>}
									to={"/terms"}
									component={Link}
								>
									{"terms"}
								</Button>
								<Button
									color={"secondary"}
									startIcon={<Icon>{"help_outlined"}</Icon>}
									to={"/about"}
									component={Link}
								>
									{"about"}
								</Button>
								<Button
									color={"secondary"}
									startIcon={<Icon>{"language"}</Icon>}
									to={"/"}
									component={Link}
								>
									{"Website"}
								</Button>
								<InstallPrompt />
								<Typography
									variant={"caption"}
									display={"flex"}
									alignContent={"center"}
									justifyContent={"center"}
								>
									{EnvConfig.Version}
								</Typography>
							</div>
						</div>
				}
				<Ad />
			</div>
		</div>
	);
}

export default Settings;