import { AppBar, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Icon, IconButton, InputLabel, MenuItem, Select, TextField, Toolbar, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import styles from "./CreateEditKick.module.scss";
import DateTimePicker from '@mui/lab/DateTimePicker';
import { Kick } from "../../../models/Kick.model";
import { HomeContext } from "../../../context/home.context";
import { AlertContext } from "../../Alert/Alert.context";
import { AppContext } from "../../../context/app.context";
import { useNavigate, useParams } from "react-router-dom";
import { Transition } from "../ViewKick/ViewKick";
import SelectLocation from "./SelectLocation";
import { isBefore } from "date-fns";

export interface ICreateEditKickProps {
	onRefresh(): void;
}

function CreateEditKick(props: ICreateEditKickProps) {
	let navigate = useNavigate();
	const Alert = useContext(AlertContext);
	const appContext = useContext(AppContext);
	const context = useContext(HomeContext);
	const [loading, setLoading] = useState(false);
	const [kick, setKick] = useState<Kick>(new Kick());
	const params = useParams<"kickId">();
	const [locationOpen, setLocationOpen] = useState(false);

	useEffect(() => {
		async function getKick() {
			const item = await context.getKickById(params.kickId!);
			if (item)
				setKick(item);
		}
		if (params.kickId && (!kick || params.kickId !== kick.id)) {
			getKick();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.kickId]);

	function onClose() {
		setKick(new Kick());
		navigate(-1);
	}

	const formValid = useMemo(() => {
		return (
			(kick.name.trim().length > 0) &&
			kick.sport &&
			kick.skill.length > 0 &&
			kick.start &&
			kick.end &&
			isBefore(kick.start, kick.end) &&
			(kick.locationName.trim().length > 0)
		);
	}, [kick]);

	console.log(kick.skill);
	return (
		<Dialog
			fullScreen={appContext.isMobile}
			open={true}
			onClose={onClose}
			TransitionComponent={Transition}
			scroll={"paper"}
		>
			<div
				className={styles.container}
				style={{ minWidth: appContext.isMobile ? "100%" : 600 }}
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<IconButton
							edge={"start"}
							color={"inherit"}
							onClick={onClose}
							aria-label={"close"}
						>
							<Icon>{"close"}</Icon>
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant={"h6"} component={"div"}>
							{`${params.kickId ? "Edit" : "Create"} Event`}
						</Typography>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<div className={styles.fields}>
						<TextField
							label={"Event Name"}
							onChange={(e) => {
								kick.name = e.target.value;
								setKick({ ...kick })
							}}
							value={kick.name}
							disabled={loading}
							error={kick.name.trim().length === 0}
							helperText={kick.name.trim().length === 0 ? "Please enter a name" : undefined}
						/>
						{
							kick.locationName &&
							<div className={styles.location}>
								<Icon>{"place"}</Icon>
								{
									!loading &&
									<Typography>{kick.locationName}</Typography>
								}
								{
									loading && <CircularProgress color={"secondary"} size={24} />
								}
							</div>
						}
						<Button
							autoFocus
							color={"secondary"}
							variant={"outlined"}
							onClick={() => setLocationOpen(true)}
							disabled={loading}
						>
							{kick.locationName ? "Change Location" : "Choose Location"}
						</Button>
						<FormControl error={!kick.sport}>
							<InputLabel id={"sport"}>{"Sport"}</InputLabel>
							<Select
								value={kick.sport || ""}
								onChange={(e) => {
									kick.sport = e.target.value;
									setKick({ ...kick });
								}}
								label={"Sport"}
								labelId={"sport"}
								disabled={loading}
							>
								{
									context.sports.map((item, index) =>
										<MenuItem key={index} value={item.id}>{item.name}</MenuItem>
									)
								}
							</Select>
							{
								!kick.sport &&
								<FormHelperText>{"Please select a sport"}</FormHelperText>
							}
						</FormControl>
						<FormControl
							required
							error={kick.skill.length === 0}
							sx={{ m: 1 }}
							component={"fieldset"}
							variant={"standard"}
						>
							<FormLabel component={"legend"}>{"Skill Level"}</FormLabel>
							<FormGroup>
								{
									context.skills.map((item, index) =>
										<FormControlLabel
											key={index}
											control={
												<Checkbox
													checked={kick.skill.includes(item.id)}
													onChange={(e, checked) => {
														if (!checked) {
															kick.skill.splice(kick.skill.indexOf(item.id), 1);
														} else {
															kick.skill.push(item.id);
														}
														setKick({ ...kick });
													}}
													name={item.name}
												/>
											}
											label={item.name}
										/>
									)
								}
							</FormGroup>
							<FormHelperText>{"Select all that apply"}</FormHelperText>
						</FormControl>
						<TextField
							label={"Description"}
							onChange={(e) => {
								kick.description = e.target.value;
								setKick({ ...kick })
							}}
							value={kick.description}
							disabled={loading}
							multiline
							rows={5}
						/>
						<DateTimePicker
							label={"Start"}
							clearable
							value={kick.start}
							onChange={(value) => {
								kick.start = value;
								setKick({ ...kick });
							}}
							minDateTime={new Date()}
							renderInput={(params) => <TextField
								{...params}
								error={(kick.start && kick.end) ? !isBefore(kick.start, kick.end) : undefined}
								helperText={((kick.start && kick.end) && !isBefore(kick.start, kick.end)) ? "Start must be before end date" : undefined}
							/>}
							disabled={loading}
							minutesStep={5}

						/>
						<DateTimePicker
							label={"End"}
							clearable
							value={kick.end}
							onChange={(value) => {
								kick.end = value;
								setKick({ ...kick });
							}}
							renderInput={(params) => <TextField
								{...params}
								error={(kick.start && kick.end) ? !isBefore(kick.start, kick.end) : undefined}
								helperText={((kick.start && kick.end) && !isBefore(kick.start, kick.end)) ? "End must be after start date" : undefined}
							/>}
							disabled={loading}
							minutesStep={5}

						/>
					</div>
				</DialogContent>
				<DialogActions>
					{
						params.kickId &&
						<Button
							autoFocus
							color={"error"}
							onClick={async () => {
								setLoading(true);
								try {
									if (await Alert.Confirm("Delete Event", "Are you sure you wish to delete this event?")) {
										await context.deleteKick(kick.id);
										props.onRefresh();
										onClose();
									}
								} catch (error) {
									appContext.logError(error as Error);
								}
								setLoading(false);
							}}
							disabled={loading}
							startIcon={<Icon>{"delete"}</Icon>}
						>
							{"delete"}
						</Button>
					}
					<Button
						autoFocus
						color={"success"}
						variant={"outlined"}
						onClick={async () => {
							setLoading(true);
							try {
								if (params.kickId) {
									await context.updateKick(kick);
									appContext.showSnack("Event Updated!", "success");
								} else {
									await context.createKick(kick);
									appContext.showSnack("Event Created!", "success");
								}
								props.onRefresh();
								onClose();
							} catch (error) {
								appContext.logError(error as Error);
							}
							setLoading(false);
						}}
						disabled={!formValid || loading}
						startIcon={<Icon>{"save"}</Icon>}
					>
						{params.kickId ? "update" : "create"}
					</Button>
				</DialogActions>
			</div>
			<SelectLocation
				open={locationOpen}
				kick={kick}
				onClose={() => setLocationOpen(false)}
				onSelect={(locationName, location) => {
					kick.location = location;
					kick.locationName = locationName;
					setKick({ ...kick });
					setLocationOpen(false);
				}}
			/>
		</Dialog>
	);
}

export default CreateEditKick;