export default class APIError {
	public Message: string = "";
	public ExceptionType: string = "";

	constructor(item?: any) {
		if (!item) return this;
		this.Message = item.Message ? item.Message : null;
		if (item.ExceptionMessage) {
			this.Message = item.ExceptionMessage;
		} else if (item.Message) {
			this.Message = item.Message;
		} else if (item["odata.error"]) {
			this.Message = item["odata.error"].message.value;
		} else if (item.error) {
			this.Message = item.error.message ? item.error.message : item.error;
		} else if (item.title) {
			this.Message = item.title;
		} else if (item.body) {
			this.Message = item.body.message;
		} else {
			this.Message = item.toString();
		}
		this.ExceptionType = item.ExceptionType ? item.ExceptionType : null;
	}
}