import { Fab, FabProps, Icon } from "@mui/material";
import { useContext } from "react";
import { HomeContext } from "../../../context/home.context";

export interface IMyLocationProps {
	fabProps: FabProps;
}

const MyLocation: React.FC<IMyLocationProps> = (props) => {
	const context = useContext(HomeContext);

	return (
		<Fab
			{...props.fabProps}
			variant={props.fabProps.variant || "circular"}
			color={props.fabProps.color || "primary"}
			aria-label={"current position"}
			onClick={() => context.setLatLng(context.myLocation)}
		>
			{<Icon>{"my_location"}</Icon>}
		</Fab>
	);
}

export default MyLocation;