import { createTheme, PaletteMode, useMediaQuery } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { MAP_THEME_TAG } from "../context/app.context";
import { THEME_TAG } from "../context/appTheme.context";

export function useDebounce(value: string, delay: number) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);
		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
}

export function useMobile(mobileWidth: number = 768): boolean {
	const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth);

	function handleResize() {
		const mobile = window.innerWidth < mobileWidth && (window.navigator.maxTouchPoints > 0);
		setIsMobile(mobile);
	}

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", () => handleResize());
		return () => window.removeEventListener("resize", () => handleResize());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isMobile;
}

export function useCurrentTheme(mode?: PaletteMode) {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
	const theme = useMemo(() => {
		const currentMode = mode || (prefersDarkMode ? "dark" : "light");
		localStorage.setItem(THEME_TAG, currentMode);
		return createTheme({
			palette: {
				mode: currentMode
			}
		});
	}, [mode, prefersDarkMode]);

	return theme;
}

export function useMapTheme(mode?: PaletteMode) {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
	const mapTheme = useMemo(() => {
		const currentMode = mode || (prefersDarkMode ? "dark" : "light");
		localStorage.setItem(MAP_THEME_TAG, currentMode);
		return currentMode;
	}, [mode, prefersDarkMode]);

	return mapTheme;
}