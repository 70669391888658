
import { useContext, useRef } from 'react';
import AppProvider, { AppContext } from "../../context/app.context";
import Home from "./Home";
import styles from './App.module.scss';
import { Grow, GrowProps, Icon, IconButton } from "@mui/material";
import { AlertProvider } from "../Alert/Alert.context";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { useMobile } from "../../hooks/app.hooks";
import Login from "./Login/Login";
import SigningIn from "./Login/SigningIn";
import { Route, Routes } from "react-router-dom";
import { NotFound } from "./NotFound/NotFound";
import { Website } from "../Website/Website";
import Bugsnag from "@bugsnag/js";
import { EnvConfig } from "../../config/env.config";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import AppThemeProvider from "../../context/appTheme.context";

Bugsnag.start({
	apiKey: EnvConfig.BugsnagKey || "",
	plugins: [new BugsnagPluginReact()]
});

export const AppRoot: React.FC = () => {
	const context = useContext(AppContext);

	return (
		<div className={styles.app}>
			{
				context.signingIn
					? <SigningIn />
					: context.user
						? <Home />
						: <Login />
			}
		</div>
	);
}

export const App: React.FC = () => {
	const snackRef = useRef<SnackbarProvider | null>(null);
	const isMobile = useMobile();

	function onClickDismiss(key: SnackbarKey) {
		if (snackRef.current)
			snackRef.current.closeSnackbar(key);
	}

	return (
		<SnackbarProvider
			TransitionComponent={(props) => <Grow {...props as GrowProps} />}
			anchorOrigin={{ horizontal: "right", vertical: isMobile ? "bottom" : "top" }}
			ref={snackRef}
			action={(key) =>
				<IconButton onClick={() => onClickDismiss(key)} color={"inherit"}>
					<Icon>{"close"}</Icon>
				</IconButton>
			}
		>
			<AppThemeProvider>
				<AlertProvider>
					<Routes>
						<Route path={"/*"} element={<Website />} />
						<Route path={"/app/*"} element={<AppProvider><AppRoot /></AppProvider>} />
						<Route path={"*"} element={<NotFound />} />
					</Routes>
				</AlertProvider>
			</AppThemeProvider>
		</SnackbarProvider>
	);
}

export default App;
