import { Button, Dialog, DialogContent, DialogTitle, Icon, IconButton, TextField, Tooltip } from "@mui/material";
import React, { useMemo, useRef, useState } from 'react';
import { Kick } from "../../models/Kick.model";
import ShareSVG from "../../assets/svg/share.svg";

export const Share: React.FC<{ kick: Kick }> = (props) => {
	const ref = useRef<HTMLInputElement | null>(null);
	const [text, setText] = useState("Copy Link");
	const [open, setOpen] = useState(false);
	const [share, setShare] = useState(false);

	const link = useMemo(() => {
		return `${window.location.origin}/app/kick/${props.kick.id}`;
	}, [props.kick]);

	function openTooltip() {
		setOpen(true);
		setTimeout(() => {
			setText("Copy Link");
			setOpen(false);
		}, 5000);
	}

	async function copyLink() {
		try {
			await navigator.clipboard.writeText(link);
			openTooltip();
			setText("Copied!");
		} catch (e) {
			openTooltip();
			setText("Failed to copy text");
		}
	}

	async function shareLink() {
		try {
			await navigator.share({
				title: "Go for a kick",
				text: "Check out this event:",
				url: link
			});
		} catch (e) {
			setText((e as Error).message);
		}
	}

	return (
		<>
			<Button
				onClick={() => setShare(true)}
				startIcon={<Icon>{"share"}</Icon>}
			>
				{"Share"}
			</Button>
			<Dialog
				open={share}
				onClose={() => setShare(false)}
			>
				<DialogTitle >{"Share Event"}</DialogTitle>
				<DialogContent style={{
					display: "grid",
					justifyItems: "center",
					alignItems: "center",
					gap: "1rem",
				}}>
					<img src={ShareSVG} alt={"Share SVG"} style={{ width: 300, maxWidth: "90%", padding: "1rem" }} />
					<TextField
						variant={"outlined"}
						label={"Link"}
						defaultValue={link}
						inputProps={{
							readOnly: true,
							ref: ref
						}}
						style={{ minWidth: 230, width: "100%" }}
					/>
					<div
						style={{
							display: "flex",
							gap: "1rem"
						}}
					>
						<Tooltip
							arrow
							placement={"top"}
							title={text}
							open={open}
							onOpen={() => openTooltip()}
						>
							<IconButton onClick={() => copyLink()}>
								<Icon>{"content_copy"}</Icon>
							</IconButton>
						</Tooltip>
						<Tooltip
							title={"Share Link"}
							arrow
							placement={"top"}
						>
							<IconButton onClick={() => shareLink()}>
								<Icon>{"share"}</Icon>
							</IconButton>
						</Tooltip>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
}

export default Share;
