import { AppBar, Icon, IconButton, Toolbar, Typography } from "@mui/material";
import { Ad } from "../../Ad/Ad";
import AboutSVG from "../../../assets/svg/about.svg";

export function About() {
	return (
		<div>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<IconButton
						edge={"start"}
						color={"inherit"}
						onClick={() => window.history.back()}
						aria-label={"close"}
					>
						<Icon>{"arrow_back"}</Icon>
					</IconButton>
					<Typography sx={{ ml: 2, flex: 1 }} variant={"h6"} component={"div"}>
						{"About"}
					</Typography>
				</Toolbar>
			</AppBar>
			<div style={{
				display: "grid",
				gap: "1rem",
				padding: "1rem",
				justifyItems: "center",
				textAlign: "center"
			}}>
				<img src={AboutSVG} alt={"About SVG"} style={{ width: 200, maxWidth: "90%", padding: "1rem" }} />
				<Typography>{"Kick is a platform for finding pickup games of sports in your area."}</Typography>
				<Typography>{"Sign up using an email address."}</Typography>
				<Typography>{"Designed to help meet people and get active by providing non-organised sport options."}</Typography>
			</div>
			<Ad />
		</div>
	);
}