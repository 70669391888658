import { Checkbox, FormControl, Icon, InputLabel, ListItemText, MenuItem, Paper, Select, TextField } from "@mui/material";
import styles from './Map.module.scss';
import { useContext } from "react";
import MapLayer from "./MapLayer";
import { MapContainer } from "react-leaflet";
import ViewKick from "../ViewKick/ViewKick";
import MyLocation from "./MyLocation";
import { HomeContext } from "../../../context/home.context";
import DatePicker from '@mui/lab/DatePicker';
import { Outlet, Route, Routes } from "react-router-dom";
import { NotFound } from "../NotFound/NotFound";
import CreateEditKick from "../CreateEditKick/CreateEditKick";
import Onboarding from "../Onboarding/Onboarding";

function Map() {
	const context = useContext(HomeContext);

	return (
		<Routes>
			<Route
				path={"/"}
				element={
					<div className={styles.map}>
						<Paper
							className={styles.search}
						>
							<Icon>{"tune"}</Icon>
							<FormControl>
								<InputLabel id={"sport"}>{"Sport"}</InputLabel>
								<Select
									labelId={"sport"}
									label={"Sport"}
									value={context.filter.sport}
									onChange={(e) => {
										context.filter.sport = e.target.value ? e.target.value : undefined;
										context.setFilter({ ...context.filter });
									}}
									MenuProps={{
										anchorOrigin: {
											horizontal: "center",
											vertical: "bottom"
										}
									}}
								>
									<MenuItem value={"all"}>
										<em>{"All Sports"}</em>
									</MenuItem>
									{
										context.sports.map((item, index) =>
											<MenuItem key={index} value={item.id}>{item.name}</MenuItem>
										)
									}
								</Select>
							</FormControl>
							<FormControl>
								<InputLabel id={"skill"}>{"Skill"}</InputLabel>
								<Select
									multiple
									labelId={"skill"}
									label={"Skill"}
									value={context.filter.skill}
									onChange={(e) => {
										context.filter.skill = e.target.value ? e.target.value as string[] : [];
										context.setFilter({ ...context.filter });
									}}
									renderValue={(selected) => {
										const items = context.skills.filter(i => selected.some(s => s === i.id));
										return items.map(i => i.name).join(", ");
									}}
								>
									{context.skills.map((skill) => (
										<MenuItem key={skill.id} value={skill.id}>
											<Checkbox checked={context.filter.skill.indexOf(skill.id) > -1} />
											<ListItemText primary={skill.name} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<DatePicker
								label={"Date"}
								clearable
								value={context.filter.date}
								onChange={(value) => {
									context.filter.date = value;
									context.setFilter({ ...context.filter });
								}}
								minDate={new Date()}
								renderInput={(params) => <TextField {...params} />}
								disableCloseOnSelect={false}
							/>
						</Paper>
						{
							context.latLng && context.zoom &&
							<MapContainer
								center={context.latLng}
								zoom={context.zoom}
								style={{ zIndex: 0 }}
								zoomControl={false}
								whenCreated={(e) => context.onBoundsChanged(e.getBounds())}
							>
								<MapLayer />
							</MapContainer>
						}
						<MyLocation
							fabProps={{
								sx: {
									position: 'fixed',
									bottom: "5rem",
									right: 16
								}
							}}
						/>
						<Outlet />
					</div>
				}
			>
				<Route path={"onboarding"} element={<Onboarding />} />
				<Route path={"kick/:kickId"} element={<ViewKick referrer={"/app"} />} />
				<Route path={"kick/:kickId/edit"} element={<CreateEditKick onRefresh={() => context.getKicksInBounds()} />} />
			</Route>
			<Route path={"*"} element={<NotFound />} />
		</Routes>
	);
}

export default Map;