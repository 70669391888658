import { AppBar, Button, Card, CardContent, Icon, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/app.context";
import { HomeContext } from "../../../context/home.context";
import { getEventDateString } from "../../../helpers/kick.helpers";
import { Kick } from "../../../models/Kick.model";
import CreateEditKick from "../CreateEditKick/CreateEditKick";
import { NotFound } from "../NotFound/NotFound";
import ViewKick from "../ViewKick/ViewKick";
import styles from "./Events.module.scss";
import SwipeableViews from "react-swipeable-views";
import NoEvents from "../../../assets/svg/events.svg";
export function Events() {
	const navigate = useNavigate();
	const appContext = useContext(AppContext);
	const context = useContext(HomeContext);
	const [tab, setTab] = useState(0);
	const [created, setCreated] = useState<Kick[]>([]);
	const [participated, setParticipated] = useState<Kick[]>([]);
	const [upcoming, setUpcoming] = useState<Kick[]>([]);

	useEffect(() => {
		changeTab();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tab]);

	async function changeTab() {
		if (tab === 0) {
			const upcomingItems = await context.getMyUpcomingEvents();
			setUpcoming(upcomingItems);
		} else if (tab === 1) {
			const participatedItems = await context.getParticipatedEvents();
			setParticipated(participatedItems);
		} else if (tab === 2) {
			const createdItems = await context.getMyCreatedEvents();
			setCreated(createdItems);
		}
	}

	function getSport(id?: string) {
		if (!id) return undefined;
		return context.sports.find(i => i.id === id);
	}

	function EventCard(props: { item: Kick }) {
		const sport = getSport(props.item.sport);
		const date = getEventDateString(props.item);
		return (
			<Card
				variant={"elevation"}
				sx={{ width: appContext.isMobile ? "100%" : 600, cursor: "pointer" }}
				onClick={() => navigate(props.item.id)}
			>
				<CardContent style={{ display: "grid", gap: "0.5rem" }}>
					<div>
						<Typography variant={"h5"}>
							{props.item.name ? props.item.name : "Unknown"}
						</Typography>
						<Typography variant={"caption"} color={"text.secondary"} gutterBottom>
							{date}
						</Typography>
					</div>
					<div className={styles.content}>
						<Icon color={"secondary"}>{sport?.icon}</Icon>
						<Typography variant={"body1"} color={"text.secondary"} >
							{sport?.name}
						</Typography>
					</div>
					<div className={styles.content}>
						<Icon>{"place"}</Icon>
						<Typography variant={"body1"} color={"text.secondary"} >
							{props.item.locationName ? props.item.locationName : "Location not found"}
						</Typography>
					</div>
				</CardContent>
			</Card>
		);
	}

	return (
		<Routes>
			<Route
				path={"/"}
				element={
					<div className={styles.container}>
						<AppBar sx={{ position: 'relative' }}>
							<Toolbar>
								<Typography sx={{ ml: 2, flex: 1 }} variant={"h6"} component={"div"}>
									{"Events"}
								</Typography>
								<Button
									color={"primary"}
									variant={"contained"}
									startIcon={<Icon>{"add"}</Icon>}
									to={"create"}
									component={Link}
								>
									{"Create"}
								</Button>
							</Toolbar>
						</AppBar>
						<Tabs
							value={tab}
							onChange={(e, newValue) => setTab(newValue)}
							centered
							variant={"fullWidth"}
						>
							<Tab label={"Upcoming"} />
							<Tab label={"Participated"} />
							<Tab label={"Created"} />
						</Tabs>
						<SwipeableViews
							index={tab}
							onChangeIndex={(i) => setTab(i)}
						>
							<div className={styles.events}>
								{
									upcoming.map((item, index) =>
										<EventCard key={index} item={item} />
									)
								}
								{
									upcoming.length === 0 &&
									<div style={{ textAlign: "center", marginTop: "2rem" }}>
										<img src={NoEvents} alt={"No Events"} style={{ width: 150, maxWidth: "90%" }} />
										<Typography variant={"subtitle1"} m={2} color={"text.secondary"}>{"Future events you are attending will appear here"}</Typography>
									</div>
								}
							</div>
							<div className={styles.events}>
								{
									participated.map((item, index) =>
										<EventCard key={index} item={item} />
									)
								}
								{
									participated.length === 0 &&
									<div style={{ textAlign: "center", marginTop: "2rem" }}>
										<img src={NoEvents} alt={"No Events"} style={{ width: 150, maxWidth: "90%" }} />
										<Typography variant={"subtitle1"} m={2} color={"text.secondary"}>{"Past events you are attending and didn't create will appear here"}</Typography>
									</div>
								}
							</div>
							<div className={styles.events}>
								{
									created.map((item, index) =>
										<EventCard key={index} item={item} />
									)
								}
								{
									created.length === 0 &&
									<div style={{ textAlign: "center", marginTop: "2rem" }}>
										<img src={NoEvents} alt={"No Events"} style={{ width: 150, maxWidth: "90%" }} />
										<Typography variant={"subtitle1"} m={2} color={"text.secondary"}>{"Events you created will appear here"}</Typography>
									</div>
								}
							</div>
						</SwipeableViews>
						<Outlet />
					</div>
				}
			>
				<Route path={"create"} element={<CreateEditKick onRefresh={() => changeTab()} />} />
				<Route path={":kickId"} element={<ViewKick referrer={"/app/events"} />} />
				<Route path={":kickId/edit"} element={<CreateEditKick onRefresh={() => changeTab()} />} />
			</Route>
			<Route path={"*"} element={<NotFound />} />
		</Routes>
	);
}