import { User } from "firebase/auth";
import APIError from "../models/APIError.model";

export default class Fetch {
	private baseUrl: string;
	private user: User;

	constructor(user: User, baseUrl: string) {
		this.user = user;
		this.baseUrl = baseUrl;
	}

	protected async GetHeaders() {
		let headers = new Headers();
		headers.append("Accept", "application/json");
		headers.append("Content-Type", "application/json");
		headers.append("Authorization", await this.user.getIdToken());
		return headers;
	}

	protected async Get<T>(endpoint: string) {
		const response = await fetch(this.baseUrl + endpoint, {
			headers: await this.GetHeaders()
		});
		return this.GetResponse<T>(response);
	}

	protected async Post<T, TData = T>(endpoint: string, data: TData) {
		const response = await fetch(this.baseUrl + endpoint, {
			method: "POST",
			body: data ? JSON.stringify(data) : undefined,
			headers: await this.GetHeaders()
		});
		return this.GetResponse<T>(response);
	}

	protected async Patch<T>(endpoint: string, data: T) {
		const response = await fetch(this.baseUrl + endpoint, {
			method: "PATCH",
			body: data ? JSON.stringify(data) : undefined,
			headers: await this.GetHeaders()
		});
		return this.GetResponse<T>(response);
	}

	protected async Put<T>(endpoint: string, data: T) {
		const response = await fetch(this.baseUrl + endpoint, {
			method: "PUT",
			body: data ? JSON.stringify(data) : undefined,
			headers: await this.GetHeaders()
		});
		return this.GetResponse<T>(response);
	}

	protected async Delete<T>(endpoint: string) {
		const response = await fetch(this.baseUrl + endpoint, {
			method: "DELETE",
			headers: await this.GetHeaders()
		});
		return this.GetResponse<T>(response);
	}

	private async GetResponse<T>(response: Response) {
		if (response.ok) {
			let text = await response.text();
			if (text) {
				return JSON.parse(text) as T;
			}
		} else {
			let json: any;
			if (response.status === 404) {
				json = { Message: "Object Not Found." };
			} else {
				json = await response.json();
			}
			const error = new APIError(json);
			console.log(error);
			throw new Error(error.Message);
		}
	}
}