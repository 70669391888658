import { addHours, startOfHour } from "date-fns";

export enum KickStatus {
	ACTIVE = "active",
	INACTIVE = "inactive"
}

export class Point {
	public coordinates: number[] = [];
	public type: string = "Point";
	constructor(item?: any) {
		if (!item) return this;
		this.coordinates = [item.lng, item.lat];
	}
}

export class Kick {
	public id: string = "";
	public type: string = "KICK";
	public created?: Date;
	public modified?: Date;
	public name: string = "";
	public description: string = "";
	public location: Point = new Point();
	public locationName: string = "";
	public sport?: string;
	public skill: string[] = [];
	public start: Date | null = addHours(startOfHour(new Date()), 1);
	public end: Date | null = addHours(startOfHour(new Date()), 2);
	public creator: string = "";
	public status: string = KickStatus.ACTIVE;
	public attending: string[] = [];

	constructor(item?: any) {
		if (!item) return this;

		this.id = item.id ? item.id : undefined;
		this.type = item.type;
		this.created = item.created ? new Date(item.created) : undefined;
		this.modified = item.modified ? new Date(item.modified) : undefined;
		this.name = item.name;
		this.description = item.description;
		this.location = item.location;
		this.locationName = item.locationName;
		this.sport = item.sport;
		this.skill = item.skill ? item.skill : [];
		this.start = item.start ? new Date(item.start) : null;
		this.end = item.end ? new Date(item.end) : null;
		this.creator = item.creator;
		this.status = item.status;
		this.attending = item.attending || [];
	}
}