import { AppBar, Icon, IconButton, Toolbar, Typography } from "@mui/material";
import PrivacySVG from "../../../assets/svg/privacy.svg";

export function Privacy() {
	return (
		<div>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<IconButton
						edge={"start"}
						color={"inherit"}
						onClick={() => window.history.back()}
						aria-label={"close"}
					>
						<Icon>{"arrow_back"}</Icon>
					</IconButton>
					<Typography sx={{ ml: 2, flex: 1 }} variant={"h6"} component={"div"}>
						{"Privacy"}
					</Typography>
				</Toolbar>
			</AppBar>
			<div style={{
				display: "grid",
				gap: "1rem",
				padding: "1rem",
				justifyItems: "center",
				textAlign: "center"
			}}>
				<Typography>{"Placeholder content for the Kick privacy policy."}</Typography>
				<img src={PrivacySVG} alt={"Privacy SVG"} style={{ width: 200, maxWidth: "90%", padding: "1rem" }} />
				<Typography>{"Ads provided by Google AdSense."}</Typography>
				<Typography>{"Firebase Authentication for user profile, which stores email, display name and profile photo."}</Typography>
				<Typography>{"Firebase Analytics for tracking usage."}</Typography>
				<Typography>{"Bugsnag for error tracking."}</Typography>
				<Typography>{"Firebase Cloud Messaging for notifications.  Notifications are opt-in."}</Typography>
				<Typography>{"Data on sport types, locations and attendance may be used to find trends in non-organised sports in your area."}</Typography>
				<Typography>{"All events are public."}</Typography>
			</div>
		</div>
	);
}