import { LatLng } from "leaflet";
import { GetPlaceName } from "../helpers/kick.helpers";
import { OSMPlace, Place } from "../models/Place.model";
import Fetch from "./Fetch";

export default class OpenStreetMapService extends Fetch {
	public async Search(query: string, bounds: string) {
		let items = await this.Get<OSMPlace[]>(`search?q=${query}&format=json&addressdetails=1&bounded=1&viewbox=${bounds}&namedetails=1`);
		const places = items?.map(item => new Place(item));
		return places!;
	}

	public async ReverseGeocode(latlng: LatLng) {
		const item = await this.Get<OSMPlace>(`reverse?format=json&addressdetails=1&lat=${latlng.lat}&lon=${latlng.lng}&namedetails=1`);
		const placeName = GetPlaceName(item);
		return placeName;
	}
}