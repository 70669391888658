import { Route, Routes } from "react-router-dom";
import { About } from "../App/About/About";
import { NotFound } from "../App/NotFound/NotFound";
import { Privacy } from "../App/Privacy/Privacy";
import { Terms } from "../App/Terms/Terms";
import { Index } from "./Index";

export function Website() {
	return (
		<div>
			<Routes>
				<Route path={"/"} element={<Index />} />
				<Route path="terms" element={<Terms />} />
				<Route path="privacy" element={<Privacy />} />
				<Route path="about" element={<About />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</div>
	);
}