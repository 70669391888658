import { AppBar, Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, Icon, IconButton, Paper, TextField, Toolbar, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDebounce } from "../../../hooks/app.hooks";
import styles from "./CreateEditKick.module.scss";
import { Place } from "../../../models/Place.model";
import { MapContainer } from "react-leaflet";
import AddEventMapLayer from "../Map/AddEventMapLayer";
import { Kick, Point } from "../../../models/Kick.model";
import { LatLng, LatLngBounds } from "leaflet";
import { HomeContext } from "../../../context/home.context";
import { AppContext } from "../../../context/app.context";
import { Transition } from "../ViewKick/ViewKick";

export interface ISelectLocationProps {
	kick: Kick;
	open: boolean;
	onClose(): void;
	onSelect(locationName: string, location: Point): void;
}

function SelectLocation(props: ISelectLocationProps) {
	const appContext = useContext(AppContext);
	const context = useContext(HomeContext);
	const [searching, setSearching] = useState(false);
	const [loading, setLoading] = useState(false);
	const [queryText, setQueryText] = useState("");
	const searchText = useDebounce(queryText, 500);
	const [places, setPlaces] = useState<Place[]>([]);
	const [bounds, setBounds] = useState<string>("");
	const [locationName, setLocationName] = useState<string>("");
	const [location, setLocation] = useState(context.latLng);

	useEffect(() => {
		if (props.kick.location.coordinates.length > 0) {
			let initialLocation = {
				lat: props.kick.location.coordinates[1],
				lng: props.kick.location.coordinates[0]
			} as LatLng;
			setLocation(initialLocation);
		} else {
			setLocation(context.latLng);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.kick, context.latLng]);

	async function getPlaces() {
		setSearching(true);
		try {
			const items = await context.searchOSM(searchText, bounds);
			setPlaces(items);
		} catch (error) {
			appContext.logError(error as Error);
		}
		setSearching(false);
	}

	useEffect(() => {
		getPlaces();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchText]);


	function onBoundsChanged(value: LatLngBounds) {
		let bboxString = value.toBBoxString();
		if (bboxString !== bounds) {
			setBounds(bboxString);
		}
	}

	async function getLocationName(latLng: LatLng) {
		setLoading(true);
		try {
			const name = await context.reverseGeocode(latLng);
			setLocationName(name);
		} catch (error) {
			appContext.logError(error as Error);
		}
		setLoading(false);
	}

	return (
		<Dialog
			fullScreen={appContext.isMobile}
			open={props.open}
			onClose={props.onClose}
			TransitionComponent={Transition}
			scroll={"paper"}
		>
			<div
				className={styles.selectLocation}
				style={{ minWidth: appContext.isMobile ? "100%" : 600 }}
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<IconButton
							edge={"start"}
							color={"inherit"}
							onClick={props.onClose}
							aria-label={"close"}
						>
							<Icon>{"close"}</Icon>
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant={"h6"} component={"div"}>
							{"Select Location"}
						</Typography>
					</Toolbar>
				</AppBar>
				<Paper className={styles.search}>
					<Autocomplete
						disablePortal
						freeSolo
						filterOptions={(x) => x}
						loading={searching}
						options={places}
						onOpen={() => getPlaces()}
						getOptionLabel={(option) => option.name ?? option}
						sx={{ width: "100%" }}
						renderInput={(params) =>
							<TextField
								{...params}
								placeholder={"Search this area"}
								onChange={(e) => setQueryText(e.target.value)}
								value={queryText}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{searching ? <CircularProgress color={"inherit"} size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									)
								}}
							/>
						}
						renderOption={(props, option) => {
							return (
								<li
									style={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap"
									}}
									{...props}
								>
									{option.name}
								</li>
							);
						}}
						onChange={(e, value) => {
							if (value !== null && typeof value === "object") {
								setLocation({ lat: value.lat, lng: value.lon } as LatLng);
								setLocationName(value.name);
							}
						}}
					/>
				</Paper>
				{
					location && context.zoom &&
					<MapContainer
						center={location}
						zoom={context.zoom}
						style={{ height: "100%", zIndex: 0 }}
						zoomControl={false}
						whenCreated={(e) => {
							onBoundsChanged(e.getBounds());
							getLocationName(e.getCenter());
						}}
					>
						<AddEventMapLayer
							latLng={location as LatLng}
							onLatLngChange={(latLng) => {
								setLocation(latLng);
								getLocationName(latLng);
							}}
							onBoundsChanged={(value) => onBoundsChanged(value)}
						/>
					</MapContainer>
				}
				<DialogContent>
					<div className={styles.location}>
						<Icon>{"place"}</Icon>
						<Typography className={styles.locationName}>
							{
								loading
									? <CircularProgress color={"secondary"} size={24} />
									: locationName
										? locationName
										: "Select a location on the map above"

							}
						</Typography>
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						autoFocus
						color={"success"}
						variant={"outlined"}
						onClick={() => {
							props.onSelect(locationName, new Point(location));
						}}
						disabled={loading}
						startIcon={<Icon>{"check"}</Icon>}
					>
						{"OK"}
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	);
}

export default SelectLocation;