
import styles from './Login.module.scss';
import { CircularProgress, Typography } from "@mui/material";
import LoadingSVG from "../../../assets/svg/loading.svg";

export default function SigningIn() {
	return (
		<div className={styles.signingIn}>
			<img src={LoadingSVG} alt={"Loading SVG"} style={{ width: 150, maxWidth: "90%" }} />
			<Typography variant={"h5"}>{"Loading..."}</Typography>
			<CircularProgress color={"secondary"} />
		</div>
	);
}