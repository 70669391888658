import { ClickAwayListener, Icon, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";

export interface IHelpIconProps {
	title: string;
}

export function HelpIcon(props: IHelpIconProps) {
	const [open, setOpen] = useState(false);

	return (
		<div>
			<ClickAwayListener
				onClickAway={() => setOpen(false)}
			>
				<Tooltip
					arrow
					title={props.title}
					open={open}
					onClose={() => setOpen(false)}
					PopperProps={{ disablePortal: true }}
					disableFocusListener
					disableTouchListener
					placement={"top"}
					componentsProps={{
						tooltip: {
							style: {
								marginBottom: 8
							}
						}
					}}
				>
					<IconButton onClick={() => setOpen(true)}>
						<Icon>{"help_outline"}</Icon>
					</IconButton>
				</Tooltip>
			</ClickAwayListener>
		</div >
	);
}