import { FirebaseError, initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import React, { useEffect, useMemo, useState } from "react";
import { getAuth, isSignInWithEmailLink, onAuthStateChanged, sendSignInLinkToEmail, signInWithEmailLink, signOut, User } from "@firebase/auth";
import { useMapTheme, useMobile } from "../hooks/app.hooks";
import Bugsnag from '@bugsnag/js'
import { OptionsObject, useSnackbar, VariantType } from "notistack";
import { Button, Icon, IconButton, PaletteMode, useTheme } from "@mui/material";
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';
import { useLocation, useNavigate } from "react-router-dom";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { EnvConfig } from "../config/env.config";
import ApiService from "../services/ApiService";
import { UserData } from "../models/UserData.model";

export const MAP_THEME_TAG = "KICK_MAP_THEME";

const firebaseConfig = {
	apiKey: "AIzaSyD2REouGyZEJueVLCc4F1eVtYZRCChd6NU",
	authDomain: "goforakick.firebaseapp.com",
	databaseURL: "https://goforakick.firebaseio.com",
	projectId: "goforakick",
	storageBucket: "goforakick.appspot.com",
	messagingSenderId: "935066268599",
	appId: "1:935066268599:web:0ebd962a576f0c504b6e32",
	measurementId: "G-56QXKHFFVM"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);
const auth = getAuth();
const actionCodeSettings = {
	url: process.env.NODE_ENV === "development" ? "http://localhost:3000/app" : 'https://kick.inprod.dev/app',
	dynamicLinkDomain: 'goforakick.page.link',
	handleCodeInApp: true,
};

export interface IAppContextProps {

}

export interface IAppContext extends IAppContextProps {
	signingIn: boolean;
	loading: boolean;
	isMobile: boolean;
	user: User | null;
	sendEmail(email: string): Promise<void>;
	signOutUser(): void;
	logError(error: Error): void;
	showSnack(message: string, variant: VariantType): void;
	setLoginEmailSent(sent: boolean): void;
	loginEmailSent: boolean;
	getFCMToken(): Promise<string | null>;
	addFCMToken(): Promise<string | null>;
	removeFCMToken(): Promise<void>;
	userData: UserData | null;
	getUserData(): Promise<void>;
	setOnboarded(): Promise<void>;
	mapMode: PaletteMode;
	toggleMapMode(): Promise<void>;
}

export const AppContext = React.createContext<IAppContext>(
	{ ...{} as IAppContext }
);

const AppProvider: React.FC<IAppContextProps> = (props) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [signingIn, setSigningIn] = useState(true);
	const [loading, setLoading] = useState(false);
	const [loginEmailSent, setLoginEmailSent] = useState(false);
	const [user, setUser] = useState<User | null>(null);
	const [userData, setUserData] = useState<UserData | null>(null);
	const isMobile = useMobile();
	const location = useLocation();
	const navigate = useNavigate();
	const theme = useTheme();
	const [mapThemeMode, setMapThemeMode] = useState<PaletteMode>(localStorage.getItem(MAP_THEME_TAG) as PaletteMode);
	const mapMode = useMapTheme(mapThemeMode);

	const api = useMemo(() => {
		return new ApiService(user!, EnvConfig.ApiUrl);
	}, [user]);

	useEffect(() => {
		logEvent(analytics, "page_view", { page_path: location.pathname });
	}, [location.pathname]);

	useEffect(() => {
		signIn(window.location.href);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		async function setThemeMode() {
			try {
				const result = await api.SetThemeMode(theme.palette.mode);
				setUserData(result);
			} catch (error) {
				logError(error as Error);
			}
		}
		if (userData && userData?.themeMode !== theme.palette.mode)
			setThemeMode();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData, theme.palette.mode]);

	useEffect(() => {
		// If you want your app to work offline and load faster, you can change
		// unregister() to register() below. Note this comes with some pitfalls.
		// Learn more about service workers: https://cra.link/PWA
		serviceWorkerRegistration.register({
			onUpdate: registration => {
				if (registration && registration.waiting) {
					showSnack("New version installed", "default", {
						persist: true,
						action: (key) => (
							<>
								<Button
									onClick={() => {
										registration.waiting!.postMessage({ type: 'SKIP_WAITING' });
										window.location.reload();
									}}
								>
									{"Refresh"}
								</Button>
								<IconButton
									onClick={() => closeSnackbar(key)}
									color={"inherit"}
								>
									<Icon>{"close"}</Icon>
								</IconButton>
							</>
						)
					});
				}
			}
		});
		onMessage(messaging, async (payload) => {
			console.log('Message received. ', payload);
			const registration = await navigator.serviceWorker.getRegistration();
			if (registration) {
				registration.showNotification(payload.data?.title || "Kick", {
					body: payload.data?.body || "Let's go for a kick",
					data: { ...payload.data },
					icon: payload.data?.icon
				});
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (user) {
			getUserData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (userData && !userData.onboarded) {
			navigate("/app/onboarding");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData]);

	async function toggleMapMode() {
		if (!userData) return;
		try {
			const mode = userData.mapMode === "light" ? "dark" : "light";
			setMapThemeMode(mode);
			const result = await api.SetMapMode(mode);
			setUserData(result);
		} catch (error) {
			logError(error as Error);
		}
	}

	async function getFCMToken() {
		let token: string | null = null;
		try {
			token = await getToken(messaging, { vapidKey: EnvConfig.VapidKey });
		} catch (error) {
			const e = error as FirebaseError;
			if (e.code !== "messaging/permission-blocked")
				logError(e);
		}
		return token;
	}

	async function getUserData() {
		try {
			const data = await api.GetUserData();
			setUserData(data);
		} catch (error) {
			logError(error as Error);
		}
	}

	async function addFCMToken() {
		const currentToken = await getFCMToken();
		try {
			if (currentToken) {
				const item = await api.AddFCMToken(currentToken);
				setUserData(item);
			}
		} catch (error) {
			logError(error as Error);
		}
		return currentToken;
	}

	async function removeFCMToken() {
		const currentToken = await getFCMToken();
		try {
			if (currentToken) {
				const item = await api.RemoveFCMToken(currentToken);
				setUserData(item);
			}
		} catch (error) {
			logError(error as Error);
		}
	}

	async function setOnboarded() {
		try {
			const item = await api.SetOnboarded();
			setUserData(item);
		} catch (error) {
			logError(error as Error);
		}
	}

	async function sendEmail(email: string) {
		setLoading(true);
		try {
			await sendSignInLinkToEmail(auth, email, actionCodeSettings);
			window.localStorage.setItem('emailForSignIn', email);
			setLoginEmailSent(true);
			showSnack("Login Email Sent!", "success");
		} catch (error) {
			logError(error as Error);
		}
		setLoading(false);
	}

	async function signIn(url: string) {
		if (isSignInWithEmailLink(auth, url)) {
			setSigningIn(true);
			try {
				let storedEmail = window.localStorage.getItem('emailForSignIn');
				if (!storedEmail) {
					storedEmail = window.prompt('Please provide your email for confirmation');
				}
				if (!storedEmail) throw new Error("Email validation failed");
				const result = await signInWithEmailLink(auth, storedEmail!, url);
				navigate("/app");
				setUser(result.user);
				window.localStorage.removeItem('emailForSignIn');
				logEvent(analytics, "login");
			} catch (error) {
				logError(error as Error);
			}
			setSigningIn(false);
		} else {
			onAuthStateChanged(auth, async (user) => {
				console.log(user);
				if (user) {
					setUser(user);
				}
				setSigningIn(false);
			});
		}
	}

	async function signOutUser() {
		await signOut(auth);
		setUser(null);
		navigate("/app");
	}

	function logError(error: Error) {
		enqueueSnackbar((error as Error).message, { variant: "error" });
		Bugsnag.notify(error);
		console.log(error);
	}

	function showSnack(message: string, variant: VariantType, options?: OptionsObject) {
		enqueueSnackbar(message, { ...options, variant });
	}

	return (
		<AppContext.Provider value={{
			...props,
			signingIn,
			loading,
			isMobile,
			user,
			sendEmail,
			signOutUser,
			logError,
			showSnack,
			setLoginEmailSent,
			loginEmailSent,
			userData,
			getUserData,
			getFCMToken,
			addFCMToken,
			removeFCMToken,
			setOnboarded,
			mapMode,
			toggleMapMode
		}}>
			{props.children}
		</AppContext.Provider>
	);
}

export default AppProvider;