import { LatLng, LatLngBounds } from "leaflet";
import { useEffect } from 'react';
import { Marker, useMapEvents } from "react-leaflet";
import MapTileLayer from "./MapTileLayer";

export interface IAddEventMapLayerProps {
	latLng: LatLng;
	onLatLngChange(latLng: LatLng): void;
	onBoundsChanged(bounds: LatLngBounds): void;
}

function AddEventMapLayer(props: IAddEventMapLayerProps) {
	const map = useMapEvents({
		locationfound: (e) => {
			map.flyTo(e.latlng.wrap(), map.getZoom());
			onBoundsChanged();
		},
		dragend: () => {
			onBoundsChanged();
		},
		zoomend: () => {
			onBoundsChanged();
		},
		click: (e) => {
			map.flyTo(e.latlng.wrap(), map.getZoom());
			props.onLatLngChange(e.latlng.wrap());
		},
	});

	function onBoundsChanged() {
		const bounds = map.getBounds();
		if (bounds) {
			props.onBoundsChanged(bounds);
		}
	}

	useEffect(() => {
		if (props.latLng) {
			map.flyTo(props.latLng, map.getZoom());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.latLng]);

	return (
		<MapTileLayer>
			{
				props.latLng &&
				<Marker position={props.latLng} />
			}
		</MapTileLayer>
	);
}

export default AddEventMapLayer;