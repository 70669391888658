import { Button, Icon, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Ad } from "../Ad/Ad";
import HomeSVG from "../../assets/svg/home.svg";

export function Index() {
	return (
		<div style={{
			padding: "1rem"
		}}>
			<div style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: "1rem",
				textAlign: "center"
			}}>
				<Typography variant={"h2"}>{"Kick"}</Typography>
				<Typography variant={"h5"}>{"Let's go for a kick!"}</Typography>
				<img src={HomeSVG} alt={"Home SVG"} style={{ width: 300, maxWidth: "90%" }} />
				<Typography variant={"body1"}>{"A platform for social sport"}</Typography>
				<Typography variant={"body1"}>{"Find public pickup games of sports in you area"}</Typography>
				<Button
					color={"primary"}
					variant={"outlined"}
					to={"/app"}
					component={Link}
				>
					{"Open Kick"}
				</Button>
				<div style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					gap: "1rem"
				}}>
					<Button
						color={"secondary"}
						startIcon={<Icon>{"verified_user"}</Icon>}
						to={"/privacy"}
						component={Link}
					>
						{"Privacy"}
					</Button>
					<Button
						color={"secondary"}
						startIcon={<Icon>{"gavel"}</Icon>}
						to={"/terms"}
						component={Link}
					>
						{"terms"}
					</Button>
					<Button
						color={"secondary"}
						startIcon={<Icon>{"help_outlined"}</Icon>}
						to={"/about"}
						component={Link}
					>
						{"about"}
					</Button>
				</div>
			</div>
			<Ad />
		</div>
	);
}